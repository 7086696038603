import React from 'react'
import CardPortriat from './Card';
import "bootstrap/dist/css/bootstrap.css";

function ConcertList(props) {
  return (
    <div >
    {props.cardData.map((sheet) => (
        <CardPortriat
          key={sheet.id}
          id={sheet.id}
          link={sheet.link}
          image={sheet.image}
          category={sheet.category}
          description={sheet.description}
        ></CardPortriat>
      ))}
      </div>
  );
}

export default ConcertList;