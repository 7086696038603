import NaviBarlight from "../../Components/layout/NavBar_light";
import { useEffect, useState } from "react";
import React from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Footer from "../../Components/layout/Logo/footer";
// import ImgData from "../../Components/image_component/img_data";
import photos from "./Photos";
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css";
import ImgCard from "../../Components/image_component/img_card";
// import Preloader from "../../Components/Preloader";





function Concert() {

  useEffect(()=>{
    document.title="Concerts"
  })
  const [index, setIndex] = useState(-1);
  // const [open, setOpen] = useState(false);
  // var onOpen = (index)=>{setIndex(index); setOpen(true)} 
  function isOpen (current){
    console.log(current)
    setIndex(parseInt(current));
  //   setOpen(true)
  // 
}
  
  return(
    
  <div  >
    <NaviBarlight/>
     <br/>
     <br/>
     <br/>
    <div style={{padding:"10px",height:"90%"}}>
    <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1,900: 3}}
                 >
                <Masonry gutter="0px" style={{padding:'0',margin:"0"}}>
                {photos.map((sheet,key) => (
        <ImgCard
          isOpen={isOpen}
          key={key}
          id={key}
          // height={height}
          image={sheet.src}
          category={sheet.category}
        />

      ))} 
                </Masonry>
            </ResponsiveMasonry>
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                />
    </div>
    <Footer/>
  </div>
  // <ImgData key={key} imgData={sheet}  isOpen={onOpen} />
  )
}

export default Concert;
