import pic1 from "../Kevin _ Edith/AA-1.jpg"
import pic2 from "../Kevin _ Edith/AA-2.jpg"
import pic3 from "../Kevin _ Edith/AA-3.jpg"
import pic4 from "../Kevin _ Edith/AA-4.jpg"
import pic5 from "../Kevin _ Edith/AA-5.jpg"
import pic6 from "../Kevin _ Edith/AA-6.jpg"
import pic7 from "../Kevin _ Edith/AA-7.jpg"
import pic8 from "../Kevin _ Edith/AA-8.jpg"
import pic9 from "../Kevin _ Edith/AA-9.jpg"
import pic10 from "../Kevin _ Edith/AA-10.jpg"
import pic12 from "../Kevin _ Edith/AA-12.jpg"
import pic13 from "../Kevin _ Edith/AA-13.jpg"
import pic14 from "../Kevin _ Edith/AA-14.jpg"
import pic15 from "../Kevin _ Edith/AA-15.jpg"
import pic16 from "../Kevin _ Edith/AA-16.jpg"
import pic17 from "../Kevin _ Edith/AA-17.jpg"
import pic18 from "../Kevin _ Edith/AA-18.jpg"
import pic19 from "../Kevin _ Edith/AA-19.jpg"
import pic20 from "../Kevin _ Edith/AA-20.jpg"
import pic21 from "../Kevin _ Edith/AA-21.jpg"
import pic22 from "../Kevin _ Edith/AA-22.jpg"
import pic23 from "../Kevin _ Edith/AA-23.jpg"
import pic24 from "../Kevin _ Edith/AA-24.jpg"
import pic25 from "../Kevin _ Edith/AA-25.jpg"
import pic27 from "../Kevin _ Edith/AA-27.jpg"
import pic28 from "../Kevin _ Edith/AA-29.jpg"
import pic29 from "../Kevin _ Edith/AA-30.jpg"
import pic30 from "../Kevin _ Edith/AA-31.jpg"
import pic31 from "../Kevin _ Edith/AA-32.jpg"
import pic32 from "../Kevin _ Edith/AA-33.jpg"
import pic33 from "../Kevin _ Edith/AA-34.jpg"
import pic34 from "../Kevin _ Edith/AA-35.jpg"
import pic35 from "../Kevin _ Edith/AA-36.jpg"
import pic36 from "../Kevin _ Edith/AA-37.jpg"
import pic37 from "../Kevin _ Edith/AA-38.jpg"
import pic38 from "../Kevin _ Edith/AA-40.jpg"
import pic39 from "../Kevin _ Edith/AA-41.jpg"
import pic40 from "../Kevin _ Edith/AA-42.jpg"
import pic41 from "../Kevin _ Edith/AA-43.jpg"
import pic42 from "../Kevin _ Edith/AA-44.jpg"
import pic43 from "../Kevin _ Edith/AA-45.jpg"
import pic44 from "../Kevin _ Edith/AA-46.jpg"
import pic45 from "../Kevin _ Edith/AA-47.jpg"
import pic46 from "../Kevin _ Edith/AA-48.jpg"
import pic47 from "../Kevin _ Edith/AA-49.jpg"
import pic48 from "../Kevin _ Edith/AA-50.jpg"
import pic49 from "../Kevin _ Edith/AA-51.jpg"
import pic50 from "../Kevin _ Edith/AA-52.jpg"
import pic51 from "../Kevin _ Edith/AA-53.jpg"
import pic52 from "../Kevin _ Edith/AA-54.jpg"
import pic53 from "../Kevin _ Edith/AA-55.jpg"
import pic54 from "../Kevin _ Edith/AA-56.jpg"
import pic55 from "../Kevin _ Edith/AA-57.jpg"
import pic56 from "../Kevin _ Edith/AA-58.jpg"
import pic57 from "../Kevin _ Edith/AA-59.jpg"
import pic58 from "../Kevin _ Edith/AA-60.jpg"
import pic59 from "../Kevin _ Edith/AA-61.jpg"
import pic60 from "../Kevin _ Edith/AA-63.jpg"
import pic61 from "../Kevin _ Edith/AA-64.jpg"
import pic62 from "../Kevin _ Edith/AA-65.jpg"
import pic63 from "../Kevin _ Edith/AA-66.jpg"
import pic64 from "../Kevin _ Edith/AA-67.jpg"
import pic65 from "../Kevin _ Edith/AA-68.jpg"
import pic66 from "../Kevin _ Edith/AA-69.jpg"
import pic67 from "../Kevin _ Edith/AA-70.jpg"
import pic68 from "../Kevin _ Edith/AA-71.jpg"
import pic69 from "../Kevin _ Edith/AA-72.jpg"
import pic70 from "../Kevin _ Edith/AA-73.jpg"
import pic71 from "../Kevin _ Edith/AA-74.jpg"
import pic72 from "../Kevin _ Edith/AA-75.jpg"
import pic73 from "../Kevin _ Edith/AA-76.jpg"
import pic74 from "../Kevin _ Edith/AA-77.jpg"
import pic75 from "../Kevin _ Edith/AA-78.jpg"
import pic76 from "../Kevin _ Edith/AA-79.jpg"
import pic77 from "../Kevin _ Edith/AA-80.jpg"
import pic78 from "../Kevin _ Edith/AA-81.jpg"
import pic79 from "../Kevin _ Edith/AA-82.jpg"
import pic80 from "../Kevin _ Edith/AA-83.jpg"
import pic81 from "../Kevin _ Edith/AA-84.jpg"
import pic82 from "../Kevin _ Edith/AA-85.jpg"
import pic83 from "../Kevin _ Edith/AA-86.jpg"
import pic84 from "../Kevin _ Edith/AA-87.jpg"
import pic85 from "../Kevin _ Edith/AA-88.jpg"
import pic86 from "../Kevin _ Edith/AA-89.jpg"
import pic87 from "../Kevin _ Edith/AA-90.jpg"
import pic88 from "../Kevin _ Edith/AA-91.jpg"
import pic89 from "../Kevin _ Edith/AA-92.jpg"
import pic90 from "../Kevin _ Edith/AA-93.jpg"
import pic91 from "../Kevin _ Edith/AA-94.jpg"
import pic92 from "../Kevin _ Edith/AA-95.jpg"
import pic93 from "../Kevin _ Edith/AA-96.jpg"
import pic94 from "../Kevin _ Edith/AA-97.jpg"
import pic95 from "../Kevin _ Edith/AA-98.jpg"
import pic96 from "../Kevin _ Edith/AA-99.jpg"


export const photo =[
    {
        id:"1",
        src:pic1,
        category:"Kevin and Edith",
      },
      {
        id:"2",
        src:pic2,
        category:"Kevin and Edith",
      },
      {
        id:"3",
        src:pic3,
        category:"Kevin and Edith",
      },
      {
        id:"4",
        src:pic4,
        category:"Kevin and Edith",
      },
      {
        id:"5",
        src:pic5,
        category:"Kevin and Edith",
      },
      {
        id:"6",
        src:pic6,
        category:"Kevin and Edith",
      },
      {
        id:"7",
        src:pic7,
        category:"Kevin and Edith",
      },
      {
        id:"8",
        src:pic8,
        category:"Kevin and Edith",
      },
      {
        id:"9",
        src:pic9,
        category:"Kevin and Edith",
      },
      {
        id:"10",
        src:pic10,
        category:"Kevin and Edith",
      },
      {
        id:"11",
        src:pic12,
        category:"Kevin and Edith",
      },
      {
        id:"12",
        src:pic13,
        category:"Kevin and Edith",
      },
      {
        id:"13",
        src:pic14,
        category:"Kevin and Edith",
      },
      {
        id:"14",
        src:pic15,
        category:"Kevin and Edith",
      },
      {
        id:"15",
        src:pic16,
        category:"Kevin and Edith",
      },
      {
        id:"16",
        src:pic17,
        category:"Kevin and Edith",
      },
      {
        id:"17",
        src:pic18,
        category:"Kevin and Edith",
      },
      {
        id:"18",
        src:pic19,
        category:"Kevin and Edith",
      },
      {
        id:"19",
        src:pic20,
        category:"Kevin and Edith",
      },
      {
        id:"20",
        src:pic21,
        category:"Kevin and Edith",
      },
      {
        id:"21",
        src:pic22,
        category:"Kevin and Edith",
      },
      {
        id:"22",
        src:pic23,
        category:"Kevin and Edith",
      },
      {
        id:"23",
        src:pic24,
        category:"Kevin and Edith",
      },
      {
        id:"24",
        src:pic25,
        category:"Kevin and Edith",
      },
      {
        id:"26",
        src:pic27,
        category:"Kevin and Edith",
      },
      {
        id:"27",
        src:pic28,
        category:"Kevin and Edith",
      },
      {
        id:"28",
        src:pic29,
        category:"Kevin and Edith",
      },
      {
        id:"29",
        src:pic30,
        category:"Kevin and Edith",
      },
      {
        id:"30",
        src:pic31,
        category:"Kevin and Edith",
      },
      {
        id:"31",
        src:pic32,
        category:"Kevin and Edith",
      },
      {
        id:"32",
        src:pic33,
        category:"Kevin and Edith",
      },
      {
        id:"33",
        src:pic34,
        category:"Kevin and Edith",
      },
      {
        id:"34",
        src:pic35,
        category:"Kevin and Edith",
      },
      {
        id:"35",
        src:pic36,
        category:"Kevin and Edith",
      },
      {
        id:"36",
        src:pic37,
        category:"Kevin and Edith",
      },
      {
        id:"37",
        src:pic38,
        category:"Kevin and Edith",
      },
      {
        id:"38",
        src:pic39,
        category:"Kevin and Edith",
      },
      {
        id:"39",
        src:pic40,
        category:"Kevin and Edith",
      },
      {
        id:"40",
        src:pic41,
        category:"Kevin and Edith",
      },
      {
        id:"41",
        src:pic42,
        category:"Kevin and Edith",
      },
      {
        id:"42",
        src:pic43,
        category:"Kevin and Edith",
      },
      {
        id:"43",
        src:pic44,
        category:"Kevin and Edith",
      },
      {
        id:"44",
        src:pic45,
        category:"Kevin and Edith",
      },
      {
        id:"45",
        src:pic46,
        category:"Kevin and Edith",
      },
      {
        id:"46",
        src:pic47,
        category:"Kevin and Edith",
      },
      {
        id:"47",
        src:pic48,
        category:"Kevin and Edith",
      },
      {
        id:"48",
        src:pic49,
        category:"Kevin and Edith",
      },
      {
        id:"49",
        src:pic50,
        category:"Kevin and Edith",
      },
      {
        id:"50",
        src:pic51,
        category:"Kevin and Edith",
      },
      {
        id:"51",
        src:pic52,
        category:"Kevin and Edith",
      },
      {
        id:"52",
        src:pic53,
        category:"Kevin and Edith",
      },
      {
        id:"53",
        src:pic54,
        category:"Kevin and Edith",
      },
      {
        id:"54",
        src:pic55,
        category:"Kevin and Edith",
      },
      {
        id:"55",
        src:pic56,
        category:"Kevin and Edith",
      },
      {
        id:"56",
        src:pic57,
        category:"Kevin and Edith",
      },
      {
        id:"57",
        src:pic58,
        category:"Kevin and Edith",
      },
      {
        id:"58",
        src:pic59,
        category:"Kevin and Edith",
      },
      {
        id:"59",
        src:pic60,
        category:"Kevin and Edith",
      },
      {
        id:"60",
        src:pic61,
        category:"Kevin and Edith",
      },
      {
        id:"61",
        src:pic62,
        category:"Kevin and Edith",
      },
      {
        id:"62",
        src:pic63,
        category:"Kevin and Edith",
      },
      {
        id:"63",
        src:pic64,
        category:"Kevin and Edith",
      },
      {
        id:"64",
        src:pic65,
        category:"Kevin and Edith",
      },
      {
        id:"65",
        src:pic66,
        category:"Kevin and Edith",
      },
      {
        id:"66",
        src:pic67,
        category:"Kevin and Edith",
      },
      {
        id:"67",
        src:pic68,
        category:"Kevin and Edith",
      },
      {
        id:"68",
        src:pic69,
        category:"Kevin and Edith",
      },
      {
        id:"69",
        src:pic70,
        category:"Kevin and Edith",
      },
      {
        id:"70",
        src:pic71,
        category:"Kevin and Edith",
      },
      {
        id:"71",
        src:pic72,
        category:"Kevin and Edith",
      },
      {
        id:"72",
        src:pic73,
        category:"Kevin and Edith",
      },
      {
        id:"73",
        src:pic74,
        category:"Kevin and Edith",
      },
      {
        id:"74",
        src:pic75,
        category:"Kevin and Edith",
      },
      {
        id:"75",
        src:pic76,
        category:"Kevin and Edith",
      },
      {
        id:"76",
        src:pic77,
        category:"Kevin and Edith",
      },
      {
        id:"77",
        src:pic78,
        category:"Kevin and Edith",
      }, {
        id:"78",
        src:pic79,
        category:"Kevin and Edith",
      },
      {
        id:"79",
        src:pic80,
        category:"Kevin and Edith",
      },
      {
        id:"80",
        src:pic81,
        category:"Kevin and Edith",
      },
      {
        id:"81",
        src:pic82,
        category:"Kevin and Edith",
      },
      {
        id:"82",
        src:pic83,
        category:"Kevin and Edith",
      },
      {
        id:"83",
        src:pic84,
        category:"Kevin and Edith",
      },
      {
        id:"84",
        src:pic85,
        category:"Kevin and Edith",
      },
      {
        id:"85",
        src:pic86,
        category:"Kevin and Edith",
      },
      {
        id:"86",
        src:pic87,
        category:"Kevin and Edith",
      },
      {
        id:"87",
        src:pic88,
        category:"Kevin and Edith",
      },
      {
        id:"88",
        src:pic89,
        category:"Kevin and Edith",
      },
      {
        id:"89",
        src:pic90,
        category:"Kevin and Edith",
      },
      {
        id:"90",
        src:pic91,
        category:"Kevin and Edith",
      },
      {
        id:"91",
        src:pic92,
        category:"Kevin and Edith",
      },
      {
        id:"92",
        src:pic93,
        category:"Kevin and Edith",
      },
      {
        id:"93",
        src:pic94,
        category:"Kevin and Edith",
      },
      {},
      {
        id:"94",
        src:pic95,
        category:"Kevin and Edith",
      },
      {},
      {},
      {
        id:"95",
        src:pic96,
        category:"Kevin and Edith",
      },
      
      
      
]

export default photo