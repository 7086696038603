import React, { useEffect, useState } from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css";
import Footer from '../../../../Components/layout/Logo/footer';
import NaviBarlight from '../../../../Components/layout/NavBar_light';
import ImgCard from '../../../../Components/image_component/img_card';
import photo from './Kev_Edith_Img';

function KevinEdith() {
  useEffect(()=>{
    document.title="Weddings | Kevin And Edith"
  })
    const [index, setIndex] = useState(-1);

    // const [open, setOpen] = useState(false);
    // var onOpen = (index)=>{setIndex(index); setOpen(true)} 
    function isOpen (current){
      console.log(current)
      setIndex(parseInt(current));
    //   setOpen(true)
    // 
    }
    
      return(
      <div>
        <NaviBarlight/>
      <div>
        <div>
        <br/>
         <br/>
         <br/>
        <div style={{padding:"10px",height:"90%"}}>
        <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1,900: 3}}
                     >
                    <Masonry gutter="0px">
    
                    {photo.map((sheet,key) => (
            //  <ImgData key={key} imgData={sheet}  isOpen={onOpen} />
            <ImgCard
              isOpen={isOpen}
              key={key}
              id={sheet.id}
              // height={height}
              image={sheet.src}
              category={sheet.category}
            ></ImgCard>
    
          ))}
                  
                    </Masonry>
                </ResponsiveMasonry>
                <Lightbox
                    slides={photo}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    />
          </div>   
          </div>
       
        </div>
        <Footer/>
      </div>
      );
}

export default KevinEdith