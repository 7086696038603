import pic1 from "../lifestyle/Aa-2-copy.jpg"
import pic2 from "../lifestyle/aa.jpg"
import pic3 from "../lifestyle/AA1.jpg"
import pic4 from "../lifestyle/AA2.jpg"
import pic5 from "../lifestyle/AA3.jpg"
import pic6 from "../lifestyle/AA4.jpg"
import pic7 from "../lifestyle/AA5.jpg"
import pic8 from "../lifestyle/AA6.jpg"
import pic9 from "../lifestyle/AA7.jpg"
import pic10 from "../lifestyle/AA8.jpg"
import pic11 from "../lifestyle/AA9.jpg"
import pic12 from "../lifestyle/AA10.jpg"
import pic13 from "../lifestyle/AA11.jpg"
import pic14 from "../lifestyle/AA12.jpg"
import pic15 from "../lifestyle/AA13.jpg"
import pic16 from "../lifestyle/AA14.jpg"
// import pic17 from "../lifestyle/AA15.jpg"
import pic18 from "../lifestyle/AA16.jpg"
import pic19 from "../lifestyle/AA17.jpg"
import pic20 from "../lifestyle/AA18.jpg"
import pic21 from "../lifestyle/AA19.jpg"
import pic22 from "../lifestyle/AA20.jpg"
import pic23 from "../lifestyle/AA21.jpg"
// import pic24 from "../lifestyle/AA22.jpg"
import pic25 from "../lifestyle/AA23.jpg"
import pic26 from "../lifestyle/AA24.jpg"
import pic27 from "../lifestyle/AA25.jpg"
import pic28 from "../lifestyle/AA26.jpg"
import pic29 from "../lifestyle/AA27.jpg"
import pic30 from "../lifestyle/AA28.jpg"
import pic31 from "../lifestyle/AA29.jpg"
import pic32 from "../lifestyle/AA30.jpg"
import pic33 from "../lifestyle/AA31.jpg"
import pic34 from "../lifestyle/AA32.jpg"
import pic35 from "../lifestyle/AA33.jpg"
import pic36 from "../lifestyle/AA34.jpg"
import pic37 from "../lifestyle/AA35.jpg"
import pic38 from "../lifestyle/AA36.jpg"
import pic39 from "../lifestyle/AA37.jpg"
import pic40 from "../lifestyle/AA38.jpg"
import pic41 from "../lifestyle/AA39.jpg"
import pic42 from "../lifestyle/AA44.jpg"
import pic43 from "../lifestyle/AA40..jpg"
import pic44 from "../lifestyle/AA41.jpg"
// import pic45 from "../lifestyle/AA50.jpg"
import pic46 from "../lifestyle/AA51.jpg"
export const photo =[
    {
        id:"1",
        src:pic1,
        category:"lifestyle",
      },
      {
        id:"2",
        src:pic2,
        category:"lifestyle",
      },
      {
        id:"3",
        src:pic3,
        category:"lifestyle",
      },
      {
        id:"4",
        src:pic4,
        category:"lifestyle",
      },
      {
        id:"5",
        src:pic5,
        category:"lifestyle",
      },
      {
        id:"6",
        src:pic6,
        category:"lifestyle",
      },
      {
        id:"7",
        src:pic7,
        category:"lifestyle",
      },
      {
        id:"8",
        src:pic8,
        category:"lifestyle",
      },
      {
        id:"9",
        src:pic9,
        category:"lifestyle",
      },
      {
        id:"10",
        src:pic10,
        category:"lifestyle",
      },
      {
        id:"11",
        src:pic11,
        category:"lifestyle",
      },
      {
        id:"12",
        src:pic12,
        category:"lifestyle",
      },
      {
        id:"13",
        src:pic13,
        category:"lifestyle",
      },
      {
        id:"14",
        src:pic14,
        category:"lifestyle",
      },
      {
        id:"15",
        src:pic15,
        category:"lifestyle",
      },
      {
        id:"16",
        src:pic16,
        category:"lifestyle",
      },
      {
        id:"17",
        src:pic18,
        category:"lifestyle",
      },
      {
        id:"18",
        src:pic19,
        category:"lifestyle",
      },
      {
        id:"19",
        src:pic20,
        category:"lifestyle",
      },
      {
        id:"20",
        src:pic21,
        category:"lifestyle",
      },
      {
        id:"21",
        src:pic22,
        category:"lifestyle",
      },
      {
        id:"22",
        src:pic23,
        category:"lifestyle",
      },

      {
        id:"23",
        src:pic25,
        category:"lifestyle",
      },
      {
        id:"24",
        src:pic26,
        category:"lifestyle",
      },
      {
        id:"25",
        src:pic27,
        category:"lifestyle",
      },
      {
        id:"26",
        src:pic28,
        category:"lifestyle",
      },
      {
        id:"27",
        src:pic29,
        category:"lifestyle",
      },
      {
        id:"28",
        src:pic35,
        category:"lifestyle",
      },
      {
        id:"29",
        src:pic30,
        category:"lifestyle",
      },
      {
        id:"30",
        src:pic31,
        category:"lifestyle",
      },
      {
        id:"31",
        src:pic32,
        category:"lifestyle",
      },
      {
        id:"32",
        src:pic33,
        category:"lifestyle",
      },
      {
        id:"33",
        src:pic34,
        category:"lifestyle",
      },
      {},
      {},
      {
        id:"34",
        src:pic36,
        category:"lifestyle",
      },
      {},
      {
        id:"35",
        src:pic37,
        category:"lifestyle",
      },
      {},
      {
        id:"36",
        src:pic38,
        category:"lifestyle",
      },
      {},
      {
        id:"37",
        src:pic39,
        category:"lifestyle",
      },
      {},
      {},
      {
        id:"38",
        src:pic40,
        category:"lifestyle",
      },
      {},
      {},
      {
        id:"39",
        src:pic41,
        category:"lifestyle",
      },
      {
        id:"40",
        src:pic42,
        category:"lifestyle",
      },
      {
        id:"41",
        src:pic43,
        category:"lifestyle",
      },
      {
        id:"42",
        src:pic44,
        category:"lifestyle",
      },
      {
        id:"43",
        src:pic46,
        category:"lifestyle",
      },
      // {
      //   id:"44",
      //   src:pic46,
      //   category:"lifestyle",
      // },
      
]


export default photo;