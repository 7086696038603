import red_1 from "../Concert/Events-Website_Pics/1red 1-1_.jpg";
import red_2 from "../Concert/Events-Website_Pics/1red 1-2_.jpg";
import red_3 from "../Concert/Events-Website_Pics/1red 1-3.jpg";
import red_4 from "../Concert/Events-Website_Pics/1red 1-3(1).jpg";
import red_5 from "../Concert/Events-Website_Pics/1red 1-3(2).jpg";
import red_6 from "../Concert/Events-Website_Pics/1red 1-4.jpg";
import red_7 from "../Concert/Events-Website_Pics/1red 1-4(1).jpg";
import red_8 from "../Concert/Events-Website_Pics/1red 1-5.jpg";
// import red_9 from "../Concert/Events-Website_Pics/1red 1-5(1).jpg";
import red_10 from "../Concert/Events-Website_Pics/1red 1-5(2).jpg";
import red_11 from "../Concert/Events-Website_Pics/1red 1-5(3).jpg";
// import red_12 from "../Concert/Events-Website_Pics/1red 1-10.jpg";
// import red_13 from "../Concert/Events-Website_Pics/1red 1-10(1).jpg";
import red_14 from "../Concert/Events-Website_Pics/1red 2.jpg";
import red_15 from "../Concert/Events-Website_Pics/1red 2(1).jpg";
import red_16 from "../Concert/Events-Website_Pics/1red 2(2).jpg";
import red_17 from "../Concert/Events-Website_Pics/1red 3.jpg";
import red_18 from "../Concert/Events-Website_Pics/1red 4.jpg";
import orange_0 from "../Concert/Events-Website_Pics/2orange 1-1.jpg"
import orange_1 from "../Concert/Events-Website_Pics/2orange 1-2.jpg"
import orange_2 from "../Concert/Events-Website_Pics/2orange 1-3.jpg"
// import orange_3 from "../Concert/Events-Website_Pics/2orange 1-4.jpg"
// import orange_4 from "../Concert/Events-Website_Pics/2orange 1-5.jpg"
import orange_5 from "../Concert/Events-Website_Pics/2orange 1-6.jpg"
import orange_6 from "../Concert/Events-Website_Pics/2orange 1-7.jpg"
import orange_7 from "../Concert/Events-Website_Pics/2orange 1-8.jpg"
// import yellow_0 from "../Concert/Events-Website_Pics/3yellow 1-1.jpg"
import yellow_1 from "../Concert/Events-Website_Pics/3yellow 1-2.jpg"
import yellow_2 from "../Concert/Events-Website_Pics/3yellow 1-3.jpg"
import yellow_3 from "../Concert/Events-Website_Pics/3yellow 1-4.jpg"
// import yellow_4 from "../Concert/Events-Website_Pics/3yellow 1-5.jpg"
import yellow_5 from "../Concert/Events-Website_Pics/3yellow 1-6.jpg"
import green_0 from "../Concert/Events-Website_Pics/4green 1-1.jpg"
// import green_1 from "../Concert/Events-Website_Pics/4green 1-2.jpg"
// import green_2 from "../Concert/Events-Website_Pics/4green 1-3.jpg"
// import green_3 from "../Concert/Events-Website_Pics/4green 1-4.jpg"
import green_4 from "../Concert/Events-Website_Pics/4green 1-5.jpg"
import green_5 from "../Concert/Events-Website_Pics/4green 1-6.jpg"
import green_6 from "../Concert/Events-Website_Pics/4green 1-7.jpg"
// import green_7 from "../Concert/Events-Website_Pics/4green 1-8.jpg"
import green_8 from "../Concert/Events-Website_Pics/4green 1-9.jpg"
import green_9 from "../Concert/Events-Website_Pics/4green 1-10.jpg"
import green_10 from "../Concert/Events-Website_Pics/4green 1-11.jpg"
import green_11 from "../Concert/Events-Website_Pics/4green 1-12.jpg"
import blue_0 from "../Concert/Events-Website_Pics/5blue 1-1.jpg"
import blue_1 from "../Concert/Events-Website_Pics/5blue 1-2.jpg"
import blue_2 from "../Concert/Events-Website_Pics/5blue 1-3.jpg"
import blue_3 from "../Concert/Events-Website_Pics/5blue 1-4.jpg"
// import blue_4 from "../Concert/Events-Website_Pics/5blue 1-5.jpg"
import blue_5 from "../Concert/Events-Website_Pics/5blue 1-6.jpg"
import blue_6 from "../Concert/Events-Website_Pics/5blue 1-7.jpg"
import blue_7 from "../Concert/Events-Website_Pics/5blue 1-8.jpg"
// import blue_8 from "../Concert/Events-Website_Pics/5blue 1-9.jpg"
import blue_9 from "../Concert/Events-Website_Pics/5blue 1-10.jpg"
// import blue_10 from "../Concert/Events-Website_Pics/5blue 1-11.jpg"
import blue_11 from "../Concert/Events-Website_Pics/5blue 1-12.jpg"
import blue_12 from "../Concert/Events-Website_Pics/5blue 1-13.jpg"
// import blue_13 from "../Concert/Events-Website_Pics/5blue 1-14.jpg"
import blue_14 from "../Concert/Events-Website_Pics/5blue 1-15.jpg"
import blue_15 from "../Concert/Events-Website_Pics/5blue 1-16.jpg"
import blue_16 from "../Concert/Events-Website_Pics/5blue 1-17.jpg"
import blue_17 from "../Concert/Events-Website_Pics/5blue 1-18.jpg"
import blue_18 from "../Concert/Events-Website_Pics/5blue 1-19.jpg"
import blue_19 from "../Concert/Events-Website_Pics/5blue 1-20.jpg"
// import purple_0 from "../Concert/Events-Website_Pics/6purple 1-1.jpg"
import purple_1 from "../Concert/Events-Website_Pics/6purple 1-2.jpg"
import purple_2 from "../Concert/Events-Website_Pics/6purple 1-3.jpg"
// import purple_3 from "../Concert/Events-Website_Pics/6purple 1-4.jpg"
import purple_4 from "../Concert/Events-Website_Pics/6purple 1-5.jpg"
// import purple_5 from "../Concert/Events-Website_Pics/6purple 1-6.jpg"
import purple_6 from "../Concert/Events-Website_Pics/6purple 1-7.jpg"
import purple_7 from "../Concert/Events-Website_Pics/6purple 1-8.jpg"
// import purple_8 from "../Concert/Events-Website_Pics/6purple 1-9.jpg"
import purple_9 from "../Concert/Events-Website_Pics/6purple 1-10.jpg"
import black_0 from "../Concert/Events-Website_Pics/7black 1-1.jpg"
import black_1 from "../Concert/Events-Website_Pics/7black 1-2.jpg"
import black_2 from "../Concert/Events-Website_Pics/7black 1-3.jpg"
// import black_3 from "../Concert/Events-Website_Pics/7black 1-4.jpg"
import black_4 from "../Concert/Events-Website_Pics/7black 1-5.jpg"
// import black_5 from "../Concert/Events-Website_Pics/7black 1-6.jpg"
import black_6 from "../Concert/Events-Website_Pics/7black 1-7.jpg"
import black_7 from "../Concert/Events-Website_Pics/7black 1-8.jpg"
// import black_8 from "../Concert/Events-Website_Pics/7black 1-9.jpg"
import black_9 from "../Concert/Events-Website_Pics/7black 1-10.jpg"
import black_10 from "../Concert/Events-Website_Pics/7black 1-11.jpg"
// import black_11 from "../Concert/Events-Website_Pics/7black 1-12.jpg"
// import black_12 from "../Concert/Events-Website_Pics/7black 1-13.jpg"
import black_13 from "../Concert/Events-Website_Pics/7black 1-14.jpg"
import black_14 from "../Concert/Events-Website_Pics/7black 1-15.jpg"






export const photos =[
  {
    id:"1",
    src:red_15,
    category:"red",
  },
  {
    id:"2",
    src:orange_7,
    category:"orange",
  },
  {
    id:"3",
    src:black_0,
    category:"black",
  },
  {
    id:"4",
    src:orange_2,
    category:"orange",
  },
  {
    id:"5",
    src:red_10,
    category:"red",
  },
  {
    id:"6",
    src:blue_15,
    category:"blue",
  },
  {
    id:"7",
    src:red_1,
    category:"red",
  },
  {
    id:"8",
    src:orange_6,
    category:"orange",
  },
  {
    id:"9",
    src:yellow_2,
    category:"yellow",
  },
  {
    id:"10",
    src:blue_2,
    category:"blue",
  },
  {
    id:"11",
    src:blue_18,
    category:"blue",
  },
  {
    id:"12",
    src:red_16,
    category:"red",
  },
  {
    id:"13",
    src:green_10,
    category:"green",
  },
  {
    id:"14",
    src:blue_17,
    category:"blue",
  },
  {
    id:"15",
    src:blue_12,
    category:"blue",
  },
  {
    id:"16",
    src:green_11,
    category:"green",
  },
  {
    id:"17",
    src:blue_11,
    category:"blue",
  },
  {
    id:"18",
    src:green_8,
    category:"green",
  },
  {
    id:"19",
    src:yellow_3,
    category:"yellow",
  },
  {
    id:"20",
    src:blue_19,
    category:"blue",
  },
  {
    id:"21",
    src:blue_0,
    category:"blue",
  },
  {
    id:"22",
    src:blue_16,
    category:"blue",
  },
  {
    id:"23",
    src:purple_2,
    category:"purple",
  },
  {
    id:"24",
    src:red_2,
    category:"red",
  },
  {
    id:"25",
    src:red_8,
    category:"red",
  },
  {
    id:"26",
    src:red_6,
    category:"red",
  },
  {
    id:"27",
    src:red_11,
    category:"red",
  },
  {
    id:"28",
    src:red_14,
    category:"red",
  },
  {
    id:"29",
    src:green_9,
    category:"green",
  },
{
  id:"3",
  src:red_3,
  category:"red",
},
{
  id:"4",
  src:red_4,
  category:"red",
},
{
  id:"5",
  src:red_5,
  category:"red",
},

{
  id:"7",
  src:red_7,
  category:"red",
},

// {
//   id:"9",
//   src:red_9,
//   category:"red",
// },

// {
//   id:"12",
//   src:red_12,
//   category:"red",
// },
// {
//   id:"13",
//   src:red_13,
//   category:"red",
// },



{
  id:"16",
  src:red_17,
  category:"red",
},
{
  id:"18",
  src:red_18,
  category:"red",
},
{
  id:"20",
  src:orange_0,
  category:"orange",
},
{
  id:"21",
  src:orange_1,
  category:"orange",
},
// {
//   id:"23",
//   src:orange_3,
//   category:"orange",
// },

// {
//   id:"25",
//   src:orange_4,
//   category:"orange",
// },
{
  id:"26",
  src:orange_5,
  category:"orange",
},


// {
//   id:"28",
//   src:yellow_0,
//   category:"yellow",
// },
{
  id:"29",
  src:yellow_1,
  category:"yellow",
},

// {
//   id:"32",
//   src:yellow_4,
//   category:"yellow",
// },
{
  id:"33",
  src:yellow_5,
  category:"yellow",
},
{
  id:"34",
  src:green_0,
  category:"green",
},
// {
//   id:"35",
//   src:green_1,
//   category:"green",
// },
// {
//   id:"36",
//   src:green_2,
//   category:"green",
// },
{
  id:"37",
  src:green_4,
  category:"green",
},
// {
//   id:"38",
//   src:green_3,
//   category:"green",
// },

{
  id:"39",
  src:green_5,
  category:"green",
},
{
  id:"40",
  src:green_6,
  category:"green",
},
// {
//   id:"42",
//   src:green_7,
//   category:"green",
// },




{
  id:"47",
  src:blue_1,
  category:"blue",
},

{
  id:"49",
  src:blue_3,
  category:"blue",
},
// {
//   id:"50",
//   src:blue_4,
//   category:"blue",
// },
{
  id:"51",
  src:blue_5,
  category:"blue",
},
{
  id:"52",
  src:blue_6,
  category:"blue",
},
{
  id:"53",
  src:blue_7,
  category:"blue",
},
// {
//   id:"54",
//   src:blue_8,
//   category:"blue",
// },
{
  id:"55",
  src:blue_9,
  category:"blue",
},

// {
//   id:"57",
//   src:blue_10,
//   category:"blue",
// },


// {
//   id:"60",
//   src:blue_13,
//   category:"blue",
// },
{
  id:"61",
  src:blue_14,
  category:"blue",
},



// {
//   id:"66",
//   src:purple_0,
//   category:"purple",
// },
{
  id:"67",
  src:purple_1,
  category:"purple",
},

// {
//   id:"69",
//   src:purple_3,
//   category:"purple",
// },
{
  id:"70",
  src:purple_4,
  category:"purple",
},
// {
//   id:"71",
//   src:purple_5,
//   category:"purple",
// },
{
  id:"72",
  src:purple_6,
  category:"purple",
},
{
  id:"73",
  src:purple_7,
  category:"purple",
},
// {
//   id:"74",
//   src:purple_8,
//   category:"purple",
// },
{
  id:"75",
  src:purple_9,
  category:"purple",
},

{
  id:"76",
  src:black_9,
  category:"black",
},

// {
//   id:"78",
//   src:black_3,
//   category:"black",
// },
{
  id:"79",
  src:black_1,
  category:"black",
},
{},
{
  id:"80",
  src:black_2,
  category:"black",
},
// {
//   id:"81",
//   src:black_8,
//   category:"black",
// },
{
  id:"82",
  src:black_7,
  category:"black",
},
// {
//   id:"83",
//   src:black_5,
//   category:"black",
// },
{
  id:"84",
  src:black_10,
  category:"black",
},
// {
//   id:"85",
//   src:black_11,
//   category:"black",
// },
// {
//   id:"86",
//   src:black_12,
//   category:"black",
// },
{},
{
  id:"87",
  src:black_13,
  category:"black",
},
{},
{
  id:"88",
  src:black_6,
  category:"black",
},
{},
{
  id:"89",
  src:black_4,
  category:"black",
},
{},
{},
{},
{
  id:"90",
  src:black_14,
  height:"height:200px",
  category:"black",
},

]

export default photos