import * as React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import styles from "../Components/Card.module.css";


function CardPortriat(props) {
  return ( 
    <div >
      <Card className={styles.card} as={Link} to={props.link}> 
        <CardActionArea >
        <CardContent  sx={{p: "0" }}>
            <CardMedia className={styles.cardImgHeight}
              component="img"
              image={props.image}
              alt={props.category}
              // sx={{ m: "0", p: "0" }}
            />
          
            <div
              className={styles.text}
            >
              <div style={{position:"absolute",bottom:15,left:10,fontWeight:"100"}}>{props.description}</div>
              
             
            </div>
            
          </CardContent>
        </CardActionArea>
      </Card>
   </div>
  );
}

export default CardPortriat;
