import pics1 from "../Yao _ Pearl/AA-1.jpg"
import pics2 from "../Yao _ Pearl/AA-2.jpg"
import pics3 from "../Yao _ Pearl/AA-3.jpg"
import pics4 from "../Yao _ Pearl/AA-4.jpg"
import pics5 from "../Yao _ Pearl/AA-5.jpg"
import pics6 from "../Yao _ Pearl/AA-6.jpg"
import pics7 from "../Yao _ Pearl/AA-7.jpg"
import pics8 from "../Yao _ Pearl/AA-8.jpg"
import pics9 from "../Yao _ Pearl/AA-9.jpg"
import pics10 from "../Yao _ Pearl/AA-10.jpg"
import pics11 from "../Yao _ Pearl/AA-11.jpg"
import pics12 from "../Yao _ Pearl/AA-12.jpg"
import pics13 from "../Yao _ Pearl/AA-13.jpg"
import pics14 from "../Yao _ Pearl/AA-14.jpg"
import pics15 from "../Yao _ Pearl/AA-15.jpg"
import pics16 from "../Yao _ Pearl/AA-16.jpg"
import pics17 from "../Yao _ Pearl/AA-17.jpg"
import pics18 from "../Yao _ Pearl/AA-18.jpg"
import pics19 from "../Yao _ Pearl/AA-19.jpg"
import pics20 from "../Yao _ Pearl/AA-20.jpg"
import pics21 from "../Yao _ Pearl/AA-21.jpg"
import pics22 from "../Yao _ Pearl/AA-22.jpg"   
import pics23 from "../Yao _ Pearl/AA-23.jpg"
import pics24 from "../Yao _ Pearl/AA-24.jpg"
import pics25 from "../Yao _ Pearl/AA-25.jpg"
import pics26 from "../Yao _ Pearl/AA-26.jpg"
import pics27 from "../Yao _ Pearl/AA-27.jpg"
import pics28 from "../Yao _ Pearl/AA-28.jpg"
import pics29 from "../Yao _ Pearl/AA-29.jpg"
import pics30 from "../Yao _ Pearl/AA-30.jpg"
import pics31 from "../Yao _ Pearl/AA-31.jpg"
import pics32 from "../Yao _ Pearl/AA-32.jpg"
import pics33 from "../Yao _ Pearl/AA-33.jpg"
import pics34 from "../Yao _ Pearl/AA-34.jpg"
import pics35 from "../Yao _ Pearl/AA-35.jpg"
import pics36 from "../Yao _ Pearl/AA-36.jpg"
import pics37 from "../Yao _ Pearl/AA-37.jpg"
import pics38 from "../Yao _ Pearl/AA-38.jpg"
import pics39 from "../Yao _ Pearl/AA-39.jpg"
import pics40 from "../Yao _ Pearl/AA-40.jpg"
import pics41 from "../Yao _ Pearl/AA-41.jpg"
import pics42 from "../Yao _ Pearl/AA-42.jpg"
import pics43 from "../Yao _ Pearl/AA-43.jpg"
import pics44 from "../Yao _ Pearl/AA-44.jpg"
import pics45 from "../Yao _ Pearl/AA-45.jpg"
import pics46 from "../Yao _ Pearl/AA-46.jpg"
import pics47 from "../Yao _ Pearl/AA-47.jpg"
import pics48 from "../Yao _ Pearl/AA-48.jpg"
import pics49 from "../Yao _ Pearl/AA-49.jpg"
import pics50 from "../Yao _ Pearl/AA-50.jpg"
import pics51 from "../Yao _ Pearl/AA-51.jpg"
import pics52 from "../Yao _ Pearl/AA-52.jpg"
import pics53 from "../Yao _ Pearl/AA-53.jpg"
import pics54 from "../Yao _ Pearl/AA-54.jpg"
import pics55 from "../Yao _ Pearl/AA-55.jpg"
import pics56 from "../Yao _ Pearl/AA-56.jpg"
import pics57 from "../Yao _ Pearl/AA-57.jpg"
import pics58 from "../Yao _ Pearl/AA-58.jpg"
import pics59 from "../Yao _ Pearl/AA-59.jpg"
import pics60 from "../Yao _ Pearl/AA-60.jpg"
import pics61 from "../Yao _ Pearl/AA-61.jpg"
import pics62 from "../Yao _ Pearl/AA-62.jpg"
import pics63 from "../Yao _ Pearl/AA-63.jpg"
import pics64 from "../Yao _ Pearl/AA-64.jpg"
import pics65 from "../Yao _ Pearl/AA-65.jpg"
import pics66 from "../Yao _ Pearl/AA-66.jpg"
import pics67 from "../Yao _ Pearl/AA-67.jpg"
import pics68 from "../Yao _ Pearl/AA-68.jpg"
import pics69 from "../Yao _ Pearl/AA-69.jpg"
import pics70 from "../Yao _ Pearl/AA-70.jpg"
import pics71 from "../Yao _ Pearl/AA-71.jpg"
import pics72 from "../Yao _ Pearl/AA-72.jpg"
import pics73 from "../Yao _ Pearl/AA-73.jpg"
import pics74 from "../Yao _ Pearl/AA-74.jpg"
import pics75 from "../Yao _ Pearl/AA-75.jpg"
import pics76 from "../Yao _ Pearl/AA-76.jpg"
import pics77 from "../Yao _ Pearl/AA-77.jpg"
import pics78 from "../Yao _ Pearl/AA-78.jpg"
import pics79 from "../Yao _ Pearl/AA-79.jpg"
import pics80 from "../Yao _ Pearl/AA-80.jpg"
import pics81 from "../Yao _ Pearl/AA-81.jpg"
import pics82 from "../Yao _ Pearl/AA-82.jpg"
import pics83 from "../Yao _ Pearl/AA-83.jpg"
import pics84 from "../Yao _ Pearl/AA-84.jpg"
import pics85 from "../Yao _ Pearl/AA-85.jpg"
import pics86 from "../Yao _ Pearl/AA-86.jpg"
import pics87 from "../Yao _ Pearl/AA-87.jpg"
import pics88 from "../Yao _ Pearl/AA-88.jpg"
import pics89 from "../Yao _ Pearl/AA-89.jpg"



export const photos =[

    {
        id:"1",
        src:pics1,
        category:"Yao And Pearl",
      },
      {
        id:"2",
        src:pics2,
        category:"Yao And Pearl",
      },
      {
        id:"3",
        src:pics3,
        category:"Yao And Pearl",
      },
      {
        id:"4",
        src:pics4,
        category:"Yao And Pearl",
      },
      {
        id:"5",
        src:pics5,
        category:"Yao And Pearl",
      },
      {
        id:"6",
        src:pics6,
        category:"Yao And Pearl",
      },
      {
        id:"7",
        src:pics7,
        category:"Yao And Pearl",
      },
      {
        id:"8",
        src:pics8,
        category:"Yao And Pearl",
      },
      {
        id:"9",
        src:pics9,
        category:"Yao And Pearl",
      },
      {
        id:"10",
        src:pics10,
        category:"Yao And Pearl",
      },
      {
        id:"11",
        src:pics11,
        category:"Yao And Pearl",
      },
      {
        id:"12",
        src:pics12,
        category:"Yao And Pearl",
      },
      {
        id:"13",
        src:pics13,
        category:"Yao And Pearl",
      },
      {
        id:"14",
        src:pics14,
        category:"Yao And Pearl",
      },
      {
        id:"15",
        src:pics15,
        category:"Yao And Pearl",
      },
      {
        id:"16",
        src:pics16,
        category:"Yao And Pearl",
      },
      {
        id:"17",
        src:pics17,
        category:"Yao And Pearl",
      },
      {
        id:"18",
        src:pics18,
        category:"Yao And Pearl",
      },
      {
        id:"19",
        src:pics19,
        category:"Yao And Pearl",
      },
      {
        id:"20",
        src:pics20,
        category:"Yao And Pearl",
      },
      {
        id:"21",
        src:pics21,
        category:"Yao And Pearl",
      },
      {
        id:"22",
        src:pics22,
        category:"Yao And Pearl",
      },
      {
        id:"23",
        src:pics23,
        category:"Yao And Pearl",
      },
      {
        id:"24",
        src:pics24,
        category:"Yao And Pearl",
      },
      {
        id:"25",
        src:pics25,
        category:"Yao And Pearl",
      },
      {
        id:"26",
        src:pics26,
        category:"Yao And Pearl",
      },
      {
        id:"27",
        src:pics27,
        category:"Yao And Pearl",
      },
      {
        id:"28",
        src:pics28,
        category:"Yao And Pearl",
      },
      {
        id:"29",
        src:pics29,
        category:"Yao And Pearl",
      },
      {
        id:"30",
        src:pics30,
        category:"Yao And Pearl",
      },
      {
        id:"31",
        src:pics31,
        category:"Yao And Pearl",
      },
      {
        id:"32",
        src:pics32,
        category:"Yao And Pearl",
      },
      {
        id:"33",
        src:pics33,
        category:"Yao And Pearl",
      },
      {
        id:"34",
        src:pics34,
        category:"Yao And Pearl",
      },
      {
        id:"35",
        src:pics35,
        category:"Yao And Pearl",
      },
      {
        id:"36",
        src:pics36,
        category:"Yao And Pearl",
      },
      {
        id:"37",
        src:pics37,
        category:"Yao And Pearl",
      },
      {
        id:"38",
        src:pics38,
        category:"Yao And Pearl",
      },
      {
        id:"39",
        src:pics39,
        category:"Yao And Pearl",
      },
      {
        id:"40",
        src:pics40,
        category:"Yao And Pearl",
      },
      {
        id:"41",
        src:pics41,
        category:"Yao And Pearl",
      },
      {
        id:"42",
        src:pics42,
        category:"Yao And Pearl",
      },
      {
        id:"43",
        src:pics43,
        category:"Yao And Pearl",
      },
      {
        id:"44",
        src:pics44,
        category:"Yao And Pearl",
      },
      {
        id:"45",
        src:pics45,
        category:"Yao And Pearl",
      },
      {
        id:"46",
        src:pics46,
        category:"Yao And Pearl",
      },
      {
        id:"47",
        src:pics47,
        category:"Yao And Pearl",
      },
      {
        id:"48",
        src:pics48,
        category:"Yao And Pearl",
      },
      {
        id:"49",
        src:pics49,
        category:"Yao And Pearl",
      },
      {
        id:"50",
        src:pics50,
        category:"Yao And Pearl",
      },
      {
        id:"51",
        src:pics51,
        category:"Yao And Pearl",
      },
      {
        id:"52",
        src:pics52,
        category:"Yao And Pearl",
      },
      {
        id:"53",
        src:pics53,
        category:"Yao And Pearl",
      },
      {
        id:"54",
        src:pics54,
        category:"Yao And Pearl",
      },
      {
        id:"55",
        src:pics55,
        category:"Yao And Pearl",
      },
      {
        id:"56",
        src:pics56,
        category:"Yao And Pearl",
      },
      {
        id:"57",
        src:pics57,
        category:"Yao And Pearl",
      },
      {
        id:"58",
        src:pics58,
        category:"Yao And Pearl",
      },
      {
        id:"59",
        src:pics59,
        category:"Yao And Pearl",
      },
      {
        id:"60",
        src:pics60,
        category:"Yao And Pearl",
      },
      {
        id:"61",
        src:pics61,
        category:"Yao And Pearl",
      },
      {
        id:"62",
        src:pics62,
        category:"Yao And Pearl",
      },
      {
        id:"63",
        src:pics63,
        category:"Yao And Pearl",
      },
      {
        id:"64",
        src:pics64,
        category:"Yao And Pearl",
      },
      {
        id:"65",
        src:pics65,
        category:"Yao And Pearl",
      },
      {
        id:"66",
        src:pics66,
        category:"Yao And Pearl",
      },
      {
        id:"67",
        src:pics67,
        category:"Yao And Pearl",
      },
      {
        id:"68",
        src:pics68,
        category:"Yao And Pearl",
      },
      {
        id:"69",
        src:pics69,
        category:"Yao And Pearl",
      },
      {
        id:"70",
        src:pics70,
        category:"Yao And Pearl",
      },
      {
        id:"71",
        src:pics71,
        category:"Yao And Pearl",
      },
      {
        id:"72",
        src:pics72,
        category:"Yao And Pearl",
      },
      {
        id:"73",
        src:pics73,
        category:"Yao And Pearl",
      },
      {
        id:"74",
        src:pics74,
        category:"Yao And Pearl",
      },
      {
        id:"75",
        src:pics75,
        category:"Yao And Pearl",
      },
      {
        id:"76",
        src:pics76,
        category:"Yao And Pearl",
      },
      {
        id:"77",
        src:pics77,
        category:"Yao And Pearl",
      },
      {
        id:"78",
        src:pics78,
        category:"Yao And Pearl",
      },
      {
        id:"79",
        src:pics79,
        category:"Yao And Pearl",
      },
      {
        id:"80",
        src:pics80,
        category:"Yao And Pearl",
      },
      {
        id:"81",
        src:pics81,
        category:"Yao And Pearl",
      },
      {
        id:"82",
        src:pics82,
        category:"Yao And Pearl",
      },
      {},
      {},
      {
        id:"83",
        src:pics83,
        category:"Yao And Pearl",
      },
      {
        id:"84",
        src:pics84,
        category:"Yao And Pearl",
      },
      {},
      {
        id:"85",
        src:pics85,
        category:"Yao And Pearl",
      },
      {
        id:"86",
        src:pics86,
        category:"Yao And Pearl",
      },
      {},
      {
        id:"87",
        src:pics87,
        category:"Yao And Pearl",
      },
      {},
      {},
      {},
      {
        id:"88",
        src:pics88,
        category:"Yao And Pearl",
      },
      {},
      {},
      {
        id:"89",
        src:pics89,
        category:"Yao And Pearl",
      },

]

export default photos