
import img1 from "../Joel_and_Simiane/AA-1.jpg"
import img2 from "../Joel_and_Simiane/AA-2.jpg"
import img3 from "../Joel_and_Simiane/AA-3.jpg"
import img4 from "../Joel_and_Simiane/AA-4.jpg"
import img5 from "../Joel_and_Simiane/AA-5.jpg"
import img6 from "../Joel_and_Simiane/AA-6.jpg"
import img7 from "../Joel_and_Simiane/AA-7.jpg"
import img8 from "../Joel_and_Simiane/AA-8.jpg"
import img9 from "../Joel_and_Simiane/AA-9.jpg"
import img10 from "../Joel_and_Simiane/AA-10.jpg"
import img11 from "../Joel_and_Simiane/AA-11.jpg"
import img12 from "../Joel_and_Simiane/AA-12.jpg"
import img13 from "../Joel_and_Simiane/AA-13.jpg"
import img14 from "../Joel_and_Simiane/AA-14.jpg"
import img15 from "../Joel_and_Simiane/AA-15.jpg"
import img16 from "../Joel_and_Simiane/AA-16.jpg"
import img17 from "../Joel_and_Simiane/AA-17.jpg"
import img18 from "../Joel_and_Simiane/AA-18.jpg"
import img19 from "../Joel_and_Simiane/AA-19.jpg"
import img20 from "../Joel_and_Simiane/AA-20.jpg"
import img21 from "../Joel_and_Simiane/AA-21.jpg"
import img22 from "../Joel_and_Simiane/AA-22.jpg"
import img23 from "../Joel_and_Simiane/AA-23.jpg"
import img24 from "../Joel_and_Simiane/AA-24.jpg"
import img25 from "../Joel_and_Simiane/AA-25.jpg"
import img26 from "../Joel_and_Simiane/AA-26.jpg"
import img27 from "../Joel_and_Simiane/AA-27.jpg"
import img28 from "../Joel_and_Simiane/AA-28.jpg"
import img29 from "../Joel_and_Simiane/AA-29.jpg"
import img30 from "../Joel_and_Simiane/AA-30.jpg"
import img31 from "../Joel_and_Simiane/AA-31.jpg"
import img32 from "../Joel_and_Simiane/AA-32.jpg"
import img33 from "../Joel_and_Simiane/AA-33.jpg"
import img34 from "../Joel_and_Simiane/AA-34.jpg"
import img35 from "../Joel_and_Simiane/AA-35.jpg"
import img36 from "../Joel_and_Simiane/AA-36.jpg"
import img37 from "../Joel_and_Simiane/AA-37.jpg"
import img38 from "../Joel_and_Simiane/AA-38.jpg"
import img39 from "../Joel_and_Simiane/AA-39.jpg"
import img40 from "../Joel_and_Simiane/AA-40.jpg"
import img41 from "../Joel_and_Simiane/AA-41.jpg"
import img42 from "../Joel_and_Simiane/AA-42.jpg"
import img43 from "../Joel_and_Simiane/AA-43.jpg"
import img44 from "../Joel_and_Simiane/AA-44.jpg"
import img45 from "../Joel_and_Simiane/AA-45.jpg"
import img46 from "../Joel_and_Simiane/AA-46.jpg"
import img47 from "../Joel_and_Simiane/AA-47.jpg"
import img48 from "../Joel_and_Simiane/AA-48.jpg"
import img49 from "../Joel_and_Simiane/AA-49.jpg"
import img50 from "../Joel_and_Simiane/AA-50.jpg"
import img51 from "../Joel_and_Simiane/AA-51.jpg"
import img52 from "../Joel_and_Simiane/AA-52.jpg"
import img53 from "../Joel_and_Simiane/AA-53.jpg"
import img54 from "../Joel_and_Simiane/AA-54.jpg"
import img55 from "../Joel_and_Simiane/AA-55.jpg"
import img56 from "../Joel_and_Simiane/AA-56.jpg"
import img57 from "../Joel_and_Simiane/AA-57.jpg"
import img58 from "../Joel_and_Simiane/AA-58.jpg"
import img59 from "../Joel_and_Simiane/AA-59.jpg"
import img60 from "../Joel_and_Simiane/AA-60.jpg"
import img61 from "../Joel_and_Simiane/AA-61.jpg"
import img62 from "../Joel_and_Simiane/AA-62.jpg"
import img63 from "../Joel_and_Simiane/AA-63.jpg"
import img64 from "../Joel_and_Simiane/AA-64.jpg"
import img65 from "../Joel_and_Simiane/AA-65.jpg"
import img66 from "../Joel_and_Simiane/AA-66.jpg"
import img67 from "../Joel_and_Simiane/AA-67.jpg"
import img68 from "../Joel_and_Simiane/AA-68.jpg"
import img69 from "../Joel_and_Simiane/AA-69.jpg"
import img70 from "../Joel_and_Simiane/AA-70.jpg"
import img71 from "../Joel_and_Simiane/AA-71.jpg"
import img72 from "../Joel_and_Simiane/AA-72.jpg"
import img73 from "../Joel_and_Simiane/AA-73.jpg"
import img74 from "../Joel_and_Simiane/AA-74.jpg"
import img75 from "../Joel_and_Simiane/AA-75.jpg"
import img76 from "../Joel_and_Simiane/AA-76.jpg"
import img77 from "../Joel_and_Simiane/AA-77.jpg"
import img78 from "../Joel_and_Simiane/AA-78.jpg"
import img79 from "../Joel_and_Simiane/AA-79.jpg"
import img80 from "../Joel_and_Simiane/AA-80.jpg"
import img81 from "../Joel_and_Simiane/AA-81.jpg"
import img82 from "../Joel_and_Simiane/AA-82.jpg"
import img83 from "../Joel_and_Simiane/AA-83.jpg"
import img84 from "../Joel_and_Simiane/AA-84.jpg"
import img85 from "../Joel_and_Simiane/AA-85.jpg"
import img86 from "../Joel_and_Simiane/AA-86.jpg"
import img87 from "../Joel_and_Simiane/AA-87.jpg"
import img88 from "../Joel_and_Simiane/AA-88.jpg"
import img89 from "../Joel_and_Simiane/AA-89.jpg"
import img90 from "../Joel_and_Simiane/AA-90.jpg"
import img91 from "../Joel_and_Simiane/AA-91.jpg"
import img92 from "../Joel_and_Simiane/AA-92.jpg"
import img93 from "../Joel_and_Simiane/AA-93.jpg"
import img94 from "../Joel_and_Simiane/AA-94.jpg"
import img95 from "../Joel_and_Simiane/AA-95.jpg"
import img96 from "../Joel_and_Simiane/AA-96.jpg"
import img97 from "../Joel_and_Simiane/AA-97.jpg"
import img98 from "../Joel_and_Simiane/AA-98.jpg"
import img99 from "../Joel_and_Simiane/AA-99.jpg"
import img100 from "../Joel_and_Simiane/AA-100.jpg"
import img101 from "../Joel_and_Simiane/AA-101.jpg"
import img102 from "../Joel_and_Simiane/AA-102.jpg"
import img103 from "../Joel_and_Simiane/AA-103.jpg"
import img104 from "../Joel_and_Simiane/AA-104.jpg"
import img105 from "../Joel_and_Simiane/AA-105.jpg"
import img106 from "../Joel_and_Simiane/AA-106.jpg"
import img107 from "../Joel_and_Simiane/AA-107.jpg"
import img108 from "../Joel_and_Simiane/AA-108.jpg"
import img109 from "../Joel_and_Simiane/AA-109.jpg"
import img110 from "../Joel_and_Simiane/AA-110.jpg"
import img111 from "../Joel_and_Simiane/AA-111.jpg"
import img112 from "../Joel_and_Simiane/AA-112.jpg"
import img113 from "../Joel_and_Simiane/AA-113.jpg"
import img114 from "../Joel_and_Simiane/AA-114.jpg"
import img115 from "../Joel_and_Simiane/AA-115.jpg"
import img116 from "../Joel_and_Simiane/AA-116.jpg"
import img117 from "../Joel_and_Simiane/AA-117.jpg"
import img118 from "../Joel_and_Simiane/AA-118.jpg"
import img119 from "../Joel_and_Simiane/AA-119.jpg"
import img120 from "../Joel_and_Simiane/AA-120.jpg"
import img121 from "../Joel_and_Simiane/AA-121.jpg"
import img122 from "../Joel_and_Simiane/AA-122.jpg"
import img123 from "../Joel_and_Simiane/AA-123.jpg"
import img124 from "../Joel_and_Simiane/AA-124.jpg"
// import img125 from "../Joel_and_Simiane/AA-125.jpg"
export const images = [

    {
        id:"1",
        src:img1,
        category:"Joel and Simiane",
      },
      {
        id:"2",
        src:img2,
        category:"Joel and Simiane",
      },
      {
        id:"3",
        src:img3,
        category:"Joel and Simiane",
      },
      {
        id:"4",
        src:img4,
        category:"Joel and Simiane",
      },
      {
        id:"5",
        src:img5,
        category:"Joel and Simiane",
      },
      {
        id:"6",
        src:img6,
        category:"Joel and Simiane",
      },
      {
        id:"7",
        src:img7,
        category:"Joel and Simiane",
      },
      {
        id:"8",
        src:img8,
        category:"Joel and Simiane",
      },
      {
        id:"9",
        src:img9,
        category:"Joel and Simiane",
      },
      {
        id:"10",
        src:img10,
        category:"Joel and Simiane",
      },
      {
        id:"11",
        src:img11,
        category:"Joel and Simiane",
      },
      {
        id:"12",
        src:img12,
        category:"Joel and Simiane",
      },
      {
        id:"13",
        src:img13,
        category:"Joel and Simiane",
      },
      {
        id:"14",
        src:img14,
        category:"Joel and Simiane",
      },
      {
        id:"15",
        src:img15,
        category:"Joel and Simiane",
      },
      {
        id:"16",
        src:img16,
        category:"Joel and Simiane",
      },
      {
        id:"17",
        src:img17,
        category:"Joel and Simiane",
      },
      {
        id:"18",
        src:img18,
        category:"Joel and Simiane",
      },
      {
        id:"19",
        src:img19,
        category:"Joel and Simiane",
      },
      {
        id:"20",
        src:img20,
        category:"Joel and Simiane",
      },
      {
        id:"21",
        src:img21,
        category:"Joel and Simiane",
      },
      {
        id:"22",
        src:img22,
        category:"Joel and Simiane",
      },
      {
        id:"23",
        src:img23,
        category:"Joel and Simiane",
      },
      {
        id:"24",
        src:img24,
        category:"Joel and Simiane",
      },
      {
        id:"25",
        src:img25,
        category:"Joel and Simiane",
      },
      {
        id:"26",
        src:img26,
        category:"Joel and Simiane",
      },
      {
        id:"27",
        src:img27,
        category:"Joel and Simiane",
      },
      {
        id:"28",
        src:img28,
        category:"Joel and Simiane",
      },
      {
        id:"29",
        src:img29,
        category:"Joel and Simiane",
      },
      {
        id:"30",
        src:img30,
        category:"Joel and Simiane",
      },
      {
        id:"31",
        src:img31,
        category:"Joel and Simiane",
      },
      {
        id:"32",
        src:img32,
        category:"Joel and Simiane",
      },
      {
        id:"33",
        src:img33,
        category:"Joel and Simiane",
      },
      {
        id:"34",
        src:img34,
        category:"Joel and Simiane",
      },
      {
        id:"35",
        src:img35,
        category:"Joel and Simiane",
      },
      {
        id:"36",
        src:img36,
        category:"Joel and Simiane",
      },
      {
        id:"37",
        src:img37,
        category:"Joel and Simiane",
      },
      {
        id:"38",
        src:img38,
        category:"Joel and Simiane",
      },
      {
        id:"39",
        src:img39,
        category:"Joel and Simiane",
      },
      {
        id:"40",
        src:img40,
        category:"Joel and Simiane",
      },
      {
        id:"41",
        src:img41,
        category:"Joel and Simiane",
      },
      {
        id:"42",
        src:img42,
        category:"Joel and Simiane",
      },
      {
        id:"43",
        src:img43,
        category:"Joel and Simiane",
      },
      {
        id:"44",
        src:img44,
        category:"Joel and Simiane",
      },
      {
        id:"45",
        src:img45,
        category:"Joel and Simiane",
      },
      {
        id:"46",
        src:img46,
        category:"Joel and Simiane",
      },
      {
        id:"47",
        src:img47,
        category:"Joel and Simiane",
      },
      {
        id:"48",
        src:img48,
        category:"Joel and Simiane",
      },
      {
        id:"49",
        src:img49,
        category:"Joel and Simiane",
      },
      {
        id:"50",
        src:img50,
        category:"Joel and Simiane",
      },
      {
        id:"51",
        src:img51,
        category:"Joel and Simiane",
      },
      {
        id:"52",
        src:img52,
        category:"Joel and Simiane",
      },
      {
        id:"53",
        src:img53,
        category:"Joel and Simiane",
      },
      {
        id:"54",
        src:img54,
        category:"Joel and Simiane",
      },
      {
        id:"55",
        src:img55,
        category:"Joel and Simiane",
      },
      {
        id:"56",
        src:img56,
        category:"Joel and Simiane",
      },
      {
        id:"57",
        src:img57,
        category:"Joel and Simiane",
      },
      {
        id:"58",
        src:img58,
        category:"Joel and Simiane",
      },
      {
        id:"59",
        src:img59,
        category:"Joel and Simiane",
      },
      {
        id:"60",
        src:img60,
        category:"Joel and Simiane",
      },
      {
        id:"61",
        src:img61,
        category:"Joel and Simiane",
      },
      {
        id:"62",
        src:img62,
        category:"Joel and Simiane",
      },
      {
        id:"63",
        src:img63,
        category:"Joel and Simiane",
      },
      {
        id:"64",
        src:img64,
        category:"Joel and Simiane",
      },
      {
        id:"65",
        src:img65,
        category:"Joel and Simiane",
      },
      {
        id:"66",
        src:img66,
        category:"Joel and Simiane",
      },
      {
        id:"67",
        src:img67,
        category:"Joel and Simiane",
      },
      {
        id:"68",
        src:img68,
        category:"Joel and Simiane",
      },
      {
        id:"69",
        src:img69,
        category:"Joel and Simiane",
      },
      {
        id:"70",
        src:img70,
        category:"Joel and Simiane",
      },
      {
        id:"71",
        src:img71,
        category:"Joel and Simiane",
      },
      {
        id:"72",
        src:img72,
        category:"Joel and Simiane",
      },
      {
        id:"73",
        src:img73,
        category:"Joel and Simiane",
      },
      {
        id:"74",
        src:img74,
        category:"Joel and Simiane",
      },
      {
        id:"75",
        src:img75,
        category:"Joel and Simiane",
      },
      {
        id:"76",
        src:img76,
        category:"Joel and Simiane",
      },
      {
        id:"77",
        src:img77,
        category:"Joel and Simiane",
      },
      {
        id:"78",
        src:img78,
        category:"Joel and Simiane",
      },
      {
        id:"79",
        src:img79,
        category:"Joel and Simiane",
      },
      {
        id:"80",
        src:img80,
        category:"Joel and Simiane",
      },
      {
        id:"81",
        src:img81,
        category:"Joel and Simiane",
      },
      {
        id:"82",
        src:img82,
        category:"Joel and Simiane",
      },
      {
        id:"83",
        src:img83,
        category:"Joel and Simiane",
      },
      {
        id:"84",
        src:img84,
        category:"Joel and Simiane",
      },
      {
        id:"85",
        src:img85,
        category:"Joel and Simiane",
      },
      {
        id:"86",
        src:img86,
        category:"Joel and Simiane",
      },
      {
        id:"87",
        src:img87,
        category:"Joel and Simiane",
      },
      {
        id:"88",
        src:img88,
        category:"Joel and Simiane",
      },
      {
        id:"89",
        src:img89,
        category:"Joel and Simiane",
      },
      {
        id:"90",
        src:img90,
        category:"Joel and Simiane",
      },
      {
        id:"91",
        src:img91,
        category:"Joel and Simiane",
      },
      {
        id:"92",
        src:img92,
        category:"Joel and Simiane",
      },
      {
        id:"93",
        src:img93,
        category:"Joel and Simiane",
      },
      {
        id:"94",
        src:img94,
        category:"Joel and Simiane",
      },
      {
        id:"95",
        src:img95,
        category:"Joel and Simiane",
      },
      {
        id:"96",
        src:img96,
        category:"Joel and Simiane",
      },
      {
        id:"97",
        src:img97,
        category:"Joel and Simiane",
      },
      {
        id:"98",
        src:img98,
        category:"Joel and Simiane",
      },
      {
        id:"99",
        src:img99,
        category:"Joel and Simiane",
      },
      {
        id:"100",
        src:img100,
        category:"Joel and Simiane",
      },
      {
        id:"101",
        src:img101,
        category:"Joel and Simiane",
      },
      {
        id:"102",
        src:img102,
        category:"Joel and Simiane",
      },
      {
        id:"103",
        src:img103,
        category:"Joel and Simiane",
      },
      {
        id:"104",
        src:img104,
        category:"Joel and Simiane",
      },
      {
        id:"105",
        src:img105,
        category:"Joel and Simiane",
      },
      {
        id:"106",
        src:img106,
        category:"Joel and Simiane",
      },
      {
        id:"107",
        src:img107,
        category:"Joel and Simiane",
      },
      {
        id:"108",
        src:img108,
        category:"Joel and Simiane",
      },
      {
        id:"109",
        src:img109,
        category:"Joel and Simiane",
      },
      {
        id:"110",
        src:img110,
        category:"Joel and Simiane",
      },
      {
        id:"111",
        src:img111,
        category:"Joel and Simiane",
      },
      {
        id:"112",
        src:img112,
        category:"Joel and Simiane",
      },
      {
        id:"113",
        src:img113,
        category:"Joel and Simiane",
      },
      {
        id:"114",
        src:img114,
        category:"Joel and Simiane",
      },
      {
        id:"115",
        src:img115,
        category:"Joel and Simiane",
      },
      {
        id:"116",
        src:img116,
        category:"Joel and Simiane",
      },
      {
        id:"117",
        src:img117,
        category:"Joel and Simiane",
      },
      {},
      {
        id:"118",
        src:img118,
        category:"Joel and Simiane",
      },
      {
        id:"119",
        src:img119,
        category:"Joel and Simiane",
      },
    {},
      {
        id:"121",
        src:img121,
        category:"Joel and Simiane",
      },
      
      {
        id:"122",
        src:img122,
        category:"Joel and Simiane",
      },
      {},
      {},
      {
        id:"120",
        src:img120,
        category:"Joel and Simiane",
      },
      {},
      {
        id:"123",
        src:img123,
        category:"Joel and Simiane",
      },
      {
        id:"124",
        src:img124,
        category:"Joel and Simiane",
      },
      
]



export default images