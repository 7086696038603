import image1 from "../David-Clay _ Esther/AA-1....jpg"
import image2 from "../David-Clay _ Esther/AA-1...jpg"
import image3 from "../David-Clay _ Esther/AA-1..jpg"
import image4 from "../David-Clay _ Esther/AA-1.jpg"
import image5 from "../David-Clay _ Esther/AA-2.jpg"
import image6 from "../David-Clay _ Esther/AA-3.jpg"
import image7 from "../David-Clay _ Esther/AA-4.jpg"
import image8 from "../David-Clay _ Esther/AA-5.jpg"
import image9 from "../David-Clay _ Esther/AA-6.jpg"
import image10 from "../David-Clay _ Esther/AA-7.jpg"
import image11 from "../David-Clay _ Esther/AA-8.jpg"
import image12 from "../David-Clay _ Esther/AA-9.jpg"
import image13 from "../David-Clay _ Esther/AA-10.jpg"
import image14 from "../David-Clay _ Esther/AA-11.jpg"
import image15 from "../David-Clay _ Esther/AA-12.jpg"
import image16 from "../David-Clay _ Esther/AA-13.jpg"
import image17 from "../David-Clay _ Esther/AA-14.jpg"
import image18 from "../David-Clay _ Esther/AA-14a.jpg"
import image19 from "../David-Clay _ Esther/AA-14b.jpg"
import image20 from "../David-Clay _ Esther/AA-14c.jpg"
import image21 from "../David-Clay _ Esther/AA-15.jpg"
import image22 from "../David-Clay _ Esther/AA-16.jpg"
import image23 from "../David-Clay _ Esther/AA-17.jpg"
import image24 from "../David-Clay _ Esther/AA-18.jpg"
import image25 from "../David-Clay _ Esther/AA-19.jpg"
import image26 from "../David-Clay _ Esther/AA-20.jpg"
import image27 from "../David-Clay _ Esther/AA-21.jpg"
import image28 from "../David-Clay _ Esther/AA-22.jpg"
import image29 from "../David-Clay _ Esther/AA-23.jpg"
import image30 from "../David-Clay _ Esther/AA-24.jpg"
import image31 from "../David-Clay _ Esther/AA-25.jpg"
import image32 from "../David-Clay _ Esther/AA-26.jpg"
import image33 from "../David-Clay _ Esther/AA-27.jpg"
import image34 from "../David-Clay _ Esther/AA-28.jpg"
import image35 from "../David-Clay _ Esther/AA-29.jpg"
import image36 from "../David-Clay _ Esther/AA-30.jpg"
import image37 from "../David-Clay _ Esther/AA-31.jpg"
import image38 from "../David-Clay _ Esther/AA-32.jpg"
import image39 from "../David-Clay _ Esther/AA-33.jpg"
import image40 from "../David-Clay _ Esther/AA-34.jpg"
import image41 from "../David-Clay _ Esther/AA-35.jpg"
import image42 from "../David-Clay _ Esther/AA-36.jpg"
import image43 from "../David-Clay _ Esther/AA-37.jpg"
import image44 from "../David-Clay _ Esther/AA-38.jpg"
import image45 from "../David-Clay _ Esther/AA-39.jpg"
import image46 from "../David-Clay _ Esther/AA-40.jpg"
import image47 from "../David-Clay _ Esther/AA-41.jpg"
import image48 from "../David-Clay _ Esther/AA-42.jpg"
import image49 from "../David-Clay _ Esther/AA-43.jpg"
import image50 from "../David-Clay _ Esther/AA-44.jpg"
import image51 from "../David-Clay _ Esther/AA-45.jpg"
import image52 from "../David-Clay _ Esther/AA-46.jpg"
import image53 from "../David-Clay _ Esther/AA-47.jpg"
import image54 from "../David-Clay _ Esther/AA-48.jpg"
import image55 from "../David-Clay _ Esther/AA-49.jpg"
import image56 from "../David-Clay _ Esther/AA-50.jpg"
import image57 from "../David-Clay _ Esther/AA-51.jpg"
import image58 from "../David-Clay _ Esther/AA-52.jpg"
import image59 from "../David-Clay _ Esther/AA-53.jpg"
import image60 from "../David-Clay _ Esther/AA-54.jpg"
import image61 from "../David-Clay _ Esther/AA-55.jpg"
import image62 from "../David-Clay _ Esther/AA-56.jpg"
import image63 from "../David-Clay _ Esther/AA-57.jpg"
import image64 from "../David-Clay _ Esther/AA-58.jpg"
import image65 from "../David-Clay _ Esther/AA-59.jpg"
import image66 from "../David-Clay _ Esther/AA-60.jpg"
import image67 from "../David-Clay _ Esther/AA-61.jpg"
import image68 from "../David-Clay _ Esther/AA-62.jpg"
import image69 from "../David-Clay _ Esther/AA-63.jpg"
import image70 from "../David-Clay _ Esther/AA-64.jpg"
import image71 from "../David-Clay _ Esther/AA-65.jpg"
import image72 from "../David-Clay _ Esther/AA-66.jpg"
import image73 from "../David-Clay _ Esther/AA-67.jpg"
import image74 from "../David-Clay _ Esther/AA-68.jpg"
import image75 from "../David-Clay _ Esther/AA-69.jpg"
import image76 from "../David-Clay _ Esther/AA-70.jpg"
import image77 from "../David-Clay _ Esther/AA-71.jpg"
import image78 from "../David-Clay _ Esther/AA-72.jpg"
import image79 from "../David-Clay _ Esther/AA-73.jpg"
import image80 from "../David-Clay _ Esther/AA-74.jpg"
import image81 from "../David-Clay _ Esther/AA-75.jpg"
import image82 from "../David-Clay _ Esther/AA-76.jpg"
import image83 from "../David-Clay _ Esther/AA-77.jpg"
import image84 from "../David-Clay _ Esther/AA-77a.jpg"
import image85 from "../David-Clay _ Esther/AA-77b.jpg"
import image86 from "../David-Clay _ Esther/AA-78.jpg"
import image87 from "../David-Clay _ Esther/AA-79.jpg"
import image88 from "../David-Clay _ Esther/AA-80.jpg"
import image89 from "../David-Clay _ Esther/AA-81.jpg"
import image90 from "../David-Clay _ Esther/AA-82.jpg"
import image91 from "../David-Clay _ Esther/AA-83.jpg"
import image92 from "../David-Clay _ Esther/AA-85.jpg"
import image93 from "../David-Clay _ Esther/AA-86.jpg"
import image94 from "../David-Clay _ Esther/AA-87.jpg"
import image95 from "../David-Clay _ Esther/AA-88.jpg"
import image96 from "../David-Clay _ Esther/AA-91.jpg"
import image97 from "../David-Clay _ Esther/AA-93.jpg"
import image98 from "../David-Clay _ Esther/AA-94.jpg"


export const photos = [
  {
    id:"1",
    src:image1
}, 
{
  id:"2",
  src:image2
}, 
{
  id:"3",
  src:image3
}, 
{
  id:"4",
  src:image4
}, 
{
  id:"5",
  src:image5
}, 
{
  id:"6",
  src:image6
}, 
{
  id:"7",
  src:image7
}, 
{
  id:"8",
  src:image8
}, 
{
  id:"9",
  src:image9
}, 
{
  id:"10",
  src:image10
}, 
{
  id:"11",
  src:image11
}, 
{
  id:"12",
  src:image12
}, 
{
  id:"13",
  src:image13
}, 
{
  id:"14",
  src:image14
}, 
{
  id:"15",
  src:image15
}, 
{
  id:"16",
  src:image16
}, 
{
  id:"17",
  src:image17
}, 
{
  id:"18",
  src:image18
}, 
{
  id:"19",
  src:image19
}, 
{
  id:"20",
  src:image20
}, 
{
  id:"21",
  src:image21
}, 
{
  id:"22",
  src:image22
}, 
{
  id:"23",
  src:image23
}, 
{
  id:"24",
  src:image24
}, 
{
  id:"25",
  src:image25
}, 
{
  id:"26",
  src:image26
}, 
{
  id:"27",
  src:image27
}, 
{
  id:"28",
  src:image28
}, 
{
  id:"29",
  src:image29
}, 
{
  id:"30",
  src:image30
}, 
{
  id:"31",
  src:image31
}, 
{
  id:"32",
  src:image32
}, 
{
  id:"33",
  src:image33
}, 
{
  id:"34",
  src:image34
}, 
{
  id:"35",
  src:image35
}, 
{
  id:"36",
  src:image36
}, 
{
  id:"37",
  src:image37
}, 
{
  id:"38",
  src:image38
}, 
{
  id:"39",
  src:image39
}, 
{
  id:"40",
  src:image40
}, 
{
  id:"41",
  src:image41
}, 
{
  id:"42",
  src:image42
}, 
{
  id:"43",
  src:image43
}, 
{
  id:"44",
  src:image44
}, 
{
  id:"45",
  src:image45
}, 
{
  id:"46",
  src:image46
}, 
{
  id:"47",
  src:image47
}, 
{
  id:"48",
  src:image48
}, 
{
  id:"49",
  src:image49
}, 
{
  id:"50",
  src:image50
}, 
{
  id:"51",
  src:image51
}, 
{
  id:"52",
  src:image52
}, 
{
  id:"53",
  src:image53
}, 
{
  id:"54",
  src:image54
}, 
{
  id:"55",
  src:image55
}, 
{
  id:"56",
  src:image56
}, 
{
  id:"57",
  src:image57
}, 
{
  id:"58",
  src:image58
}, 
{
  id:"59",
  src:image59
}, 
{
  id:"60",
  src:image60
}, 
{
  id:"61",
  src:image61
},
{
  id:"62",
  src:image62
}, 
{
  id:"63",
  src:image63
},
{
  id:"64",
  src:image64
},
{
  id:"65",
  src:image65
},
{
  id:"66",
  src:image66
},
{
  id:"67",
  src:image67
},
{
  id:"68",
  src:image68
},
{
  id:"69",
  src:image69
},
{
  id:"70",
  src:image70
},
{
  id:"71",
  src:image71
},
{
  id:"72",
  src:image72
},
{
  id:"73",
  src:image73
},
{
  id:"74",
  src:image74
},
{
  id:"75",
  src:image75
},
{
  id:"76",
  src:image76
},
{
  id:"77",
  src:image77
},
{
  id:"78",
  src:image78
},
{
  id:"79",
  src:image79
},
{
  id:"80",
  src:image80
},
{
  id:"81",
  src:image81
},
{
  id:"82",
  src:image82
},
{
  id:"83",
  src:image83
},
{
  id:"84",
  src:image84
},
{
  id:"85",
  src:image85
},
{
  id:"86",
  src:image86
},
{
  id:"87",
  src:image87
},
{
  id:"88",
  src:image88
},
{
  id:"89",
  src:image89
},
{
  id:"90",
  src:image90
},
{
  id:"91",
  src:image91
},
{
  id:"92",
  src:image92
},
{
  id:"93",
  src:image93
},
{
  id:"94",
  src:image94
},
{
  id:"95",
  src:image95
},
{
  id:"96",
  src:image96
},
{},
{
  id:"97",
  src:image97
},
{},
{},
{
  id:"98",
  src:image98
},
]
export default photos