import pic1 from "../Ewurafua-kwasi/AA--2.jpg"
import pic2 from "../Ewurafua-kwasi/AA--3.jpg"
import pic3 from "../Ewurafua-kwasi/AA--4.jpg"
import pic4 from "../Ewurafua-kwasi/AA--5.jpg"
import pic5 from "../Ewurafua-kwasi/AA--6.jpg"
import pic6 from "../Ewurafua-kwasi/AA--7.jpg"
import pic7 from "../Ewurafua-kwasi/AA--8.jpg"
import pic8 from "../Ewurafua-kwasi/AA--9.jpg"
import pic9 from "../Ewurafua-kwasi/AA--10.jpg"
import pic10 from "../Ewurafua-kwasi/AA--11.jpg"
import pic11 from "../Ewurafua-kwasi/AA--12.jpg"
import pic12 from "../Ewurafua-kwasi/AA--13.jpg"
import pic13 from "../Ewurafua-kwasi/AA--14.jpg"
import pic14 from "../Ewurafua-kwasi/AA--15.jpg"
import pic15 from "../Ewurafua-kwasi/AA-.jpg"
import pic16 from "../Ewurafua-kwasi/AA-2.jpg"
import pic17 from "../Ewurafua-kwasi/AA-2123.jpg"
import pic18 from "../Ewurafua-kwasi/AA-7246.jpg"
import pic19 from "../Ewurafua-kwasi/AA-7258.jpg"
import pic20 from "../Ewurafua-kwasi/AA-7399.jpg"
import pic21 from "../Ewurafua-kwasi/AA-7493.jpg"
import pic22 from "../Ewurafua-kwasi/AA-7624.jpg"
import pic23 from "../Ewurafua-kwasi/AA-7666.jpg"
import pic24 from "../Ewurafua-kwasi/AA-7719.jpg"
import pic25 from "../Ewurafua-kwasi/AA-7812.jpg"
import pic26 from "../Ewurafua-kwasi/AA-7817.jpg"
import pic27 from "../Ewurafua-kwasi/AA-7838.jpg"
import pic28 from "../Ewurafua-kwasi/AA-8715.jpg"
import pic29 from "../Ewurafua-kwasi/AA-8790.jpg"
import pic30 from "../Ewurafua-kwasi/AA-8841.jpg"
import pic31 from "../Ewurafua-kwasi/AA-8857.jpg"
import pic32 from "../Ewurafua-kwasi/AA-8930.jpg"
import pic33 from "../Ewurafua-kwasi/AA-8995.jpg"
import pic34 from "../Ewurafua-kwasi/AA-9050.jpg"



export const photos =[
    {
        id:"1",
        src:pic1,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"2",
        src:pic2,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"3",
        src:pic3,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"4",
        src:pic4,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"5",
        src:pic5,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"6",
        src:pic6,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"7",
        src:pic7,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"8",
        src:pic8,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"9",
        src:pic9,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"10",
        src:pic10,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"11",
        src:pic11,
        category:"Ewurafua and Kwasi",
      },

      {
        id:"12",
        src:pic12,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"13",
        src:pic13,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"14",
        src:pic14,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"15",
        src:pic15,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"16",
        src:pic16,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"17",
        src:pic17,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"18",
        src:pic18,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"19",
        src:pic19,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"20",
        src:pic20,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"21",
        src:pic21,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"22",
        src:pic22,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"23",
        src:pic23,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"24",
        src:pic24,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"25",
        src:pic25,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"26",
        src:pic26,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"27",
        src:pic27,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"28",
        src:pic28,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"29",
        src:pic29,
        category:"Ewurafua and Kwasi",
      },
      {},
      {},
      {},
      {
        id:"30",
        src:pic30,
        category:"Ewurafua and Kwasi",
      },
      {},
      {},
      {
        id:"31",
        src:pic31,
        category:"Ewurafua and Kwasi",
      },
      {
        id:"32",
        src:pic32,
        category:"Ewurafua and Kwasi",
      },
      {},
      {
        id:"33",
        src:pic33,
        category:"Ewurafua and Kwasi",
      },
      {},
      {},
      {
        id:"34",
        src:pic34,
        category:"Ewurafua and Kwasi",
      },



]



export default photos;