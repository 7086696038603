import React from 'react';

import {Routes,Route} from 'react-router-dom'
import About from './Pages/About/About';
import Concert from './Pages/Concert/Concert';
import Contact from './Pages/Contact/Contact';
import Home from './Pages/Home/Home';
import Wedding from './Pages/Wedding/Wedding';
import DavidClayAndEster from './Pages/Wedding/Events/David-Clay _ Esther/davidClayAndEster';
import KevinEdith from './Pages/Wedding/Events/Kevin _ Edith/Kevin_Edith';
import YaoPearl from './Pages/Wedding/Events/Yao _ Pearl/YaoPearl';
import Lifestyle from './Pages/Protrait/Lifestyle';
import RoselineDavid from './Pages/Wedding/Events/Roseline-and-David/Roseline_and_David';
import EwurafuaKwasi from './Pages/Wedding/Events/Ewurafua-kwasi/Ewurafua_Kwasi';
import JoelSimiane from './Pages/Wedding/Events/Joel_and_Simiane/Joel_and_Simiane';
function App() {
  return (
    <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/lifestyle" element={<Lifestyle/>}/>
    <Route path="/weddings" element={<Wedding/>}/>
    <Route path="/concerts" element={<Concert/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/david_clay_and_ester" element={<DavidClayAndEster/>}/>
    <Route path="/book" element={<About/>}/>
    <Route path="/yao_and_pearl" element={<YaoPearl/>}/>
    <Route path="/kevin_and_edith" element={<KevinEdith/>}/>
    <Route path="/roseline_and_david" element={<RoselineDavid/>}/>
    <Route path="/ewurafua_and_kwasi" element={<EwurafuaKwasi/>}/>
    <Route path="/joel_and_simiane" element={<JoelSimiane/>}/>
    <Route path="*" element={<Home/>}/>
    </Routes>
    
 
  );
}

export default App;
