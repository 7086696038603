import img1 from "../Roseline-and-David/AA-1.jpg"
import img2 from "../Roseline-and-David/AA-2.jpg"
import img3 from "../Roseline-and-David/AA-3.jpg"
import img4 from "../Roseline-and-David/AA-4.jpg"
import img5 from "../Roseline-and-David/AA-5.jpg"
import img6 from "../Roseline-and-David/AA-6.jpg"
import img7 from "../Roseline-and-David/AA-7.jpg"
import img8 from "../Roseline-and-David/AA-8.jpg"
import img9 from "../Roseline-and-David/AA-9.jpg"
import img10 from "../Roseline-and-David/AA-10.jpg"
import img11 from "../Roseline-and-David/AA-11.jpg"
import img12 from "../Roseline-and-David/AA-12.jpg"
import img13 from "../Roseline-and-David/AA-13.jpg"
import img14 from "../Roseline-and-David/AA-14.jpg"
import img15 from "../Roseline-and-David/AA-15.jpg"
import img16 from "../Roseline-and-David/AA-16.jpg"
import img17 from "../Roseline-and-David/AA-17.jpg"
import img18 from "../Roseline-and-David/AA-18.jpg"
import img19 from "../Roseline-and-David/AA-19.jpg"
import img20 from "../Roseline-and-David/AA-20.jpg"
import img21 from "../Roseline-and-David/AA-21.jpg"
import img22 from "../Roseline-and-David/AA-22.jpg"
import img23 from "../Roseline-and-David/AA-23.jpg"
import img24 from "../Roseline-and-David/AA-24.jpg"
import img25 from "../Roseline-and-David/AA-25.jpg"
import img26 from "../Roseline-and-David/AA-26.jpg"
import img27 from "../Roseline-and-David/AA-27.jpg"
import img28 from "../Roseline-and-David/AA-28.jpg"
import img29 from "../Roseline-and-David/AA-29.jpg"
import img30 from "../Roseline-and-David/AA-30.jpg"
import img31 from "../Roseline-and-David/AA-31.jpg"
import img32 from "../Roseline-and-David/AA-32.jpg"
import img33 from "../Roseline-and-David/AA-33.jpg"
import img34 from "../Roseline-and-David/AA-34.jpg"
import img35 from "../Roseline-and-David/AA-35.jpg"
import img36 from "../Roseline-and-David/AA-36.jpg"
import img37 from "../Roseline-and-David/AA-37.jpg"
import img38 from "../Roseline-and-David/AA-38.jpg"
import img39 from "../Roseline-and-David/AA-39.jpg"
import img40 from "../Roseline-and-David/AA-40.jpg"
import img41 from "../Roseline-and-David/AA-41.jpg"
import img42 from "../Roseline-and-David/AA-42.jpg"
import img43 from "../Roseline-and-David/AA-43.jpg"
import img44 from "../Roseline-and-David/AA-44.jpg"
import img45 from "../Roseline-and-David/AA-45.jpg"
import img46 from "../Roseline-and-David/AA-46.jpg"
import img47 from "../Roseline-and-David/AA-47.jpg"
import img48 from "../Roseline-and-David/AA-48.jpg"
import img49 from "../Roseline-and-David/AA-49.jpg"
import img50 from "../Roseline-and-David/AA-50.jpg"
import img51 from "../Roseline-and-David/AA-51.jpg"
import img52 from "../Roseline-and-David/AA-52.jpg"
import img53 from "../Roseline-and-David/AA-53.jpg"
import img54 from "../Roseline-and-David/AA-54.jpg"
import img55 from "../Roseline-and-David/AA-55.jpg"
import img56 from "../Roseline-and-David/AA-56.jpg"
import img57 from "../Roseline-and-David/AA-57.jpg"
import img58 from "../Roseline-and-David/AA-58.jpg"
import img59 from "../Roseline-and-David/AA-59.jpg"
import img60 from "../Roseline-and-David/AA-60.jpg"
import img61 from "../Roseline-and-David/AA-61.jpg"
import img62 from "../Roseline-and-David/AA-62.jpg"
import img63 from "../Roseline-and-David/AA-63.jpg"
import img64 from "../Roseline-and-David/AA-64.jpg"
import img65 from "../Roseline-and-David/AA-65.jpg"
import img66 from "../Roseline-and-David/AA-66.jpg"
import img67 from "../Roseline-and-David/AA-67.jpg"
import img68 from "../Roseline-and-David/AA-68.jpg"
import img69 from "../Roseline-and-David/AA-69.jpg"
import img70 from "../Roseline-and-David/AA-70.jpg"
import img71 from "../Roseline-and-David/AA-71.jpg"
import img72 from "../Roseline-and-David/AA-72.jpg"
import img73 from "../Roseline-and-David/AA-73.jpg"
import img74 from "../Roseline-and-David/AA-74.jpg"
import img75 from "../Roseline-and-David/AA-75.jpg"
import img76 from "../Roseline-and-David/AA-76.jpg"
import img77 from "../Roseline-and-David/AA-77.jpg"
import img78 from "../Roseline-and-David/AA-78.jpg"
import img79 from "../Roseline-and-David/AA-79.jpg"
import img80 from "../Roseline-and-David/AA-80.jpg"





export const photo = [

    {
        id:"1",
        src:img1,
        category:"Roseline and David",
      },
      {
        id:"2",
        src:img2,
        category:"Roseline and David",
      },
      {
        id:"3",
        src:img3,
        category:"Roseline and David",
      },
      {
        id:"4",
        src:img4,
        category:"Roseline and David",
      },
      {
        id:"5",
        src:img5,
        category:"Roseline and David",
      },
      {
        id:"6",
        src:img6,
        category:"Roseline and David",
      },
      {
        id:"7",
        src:img7,
        category:"Roseline and David",
      },
      {
        id:"8",
        src:img8,
        category:"Roseline and David",
      },
      {
        id:"9",
        src:img9,
        category:"Roseline and David",
      },
      {
        id:"10",
        src:img10,
        category:"Roseline and David",
      },
      {
        id:"11",
        src:img11,
        category:"Roseline and David",
      },
      {
        id:"12",
        src:img12,
        category:"Roseline and David",
      },
      {
        id:"13",
        src:img13,
        category:"Roseline and David",
      },
      {
        id:"14",
        src:img14,
        category:"Roseline and David",
      },
      {
        id:"15",
        src:img15,
        category:"Roseline and David",
      },
      {
        id:"16",
        src:img16,
        category:"Roseline and David",
      },
      {
        id:"17",
        src:img17,
        category:"Roseline and David",
      },
      {
        id:"18",
        src:img18,
        category:"Roseline and David",
      },
      {
        id:"19",
        src:img19,
        category:"Roseline and David",
      },
      {
        id:"20",
        src:img20,
        category:"Roseline and David",
      },
      {
        id:"21",
        src:img21,
        category:"Roseline and David",
      },
      {
        id:"22",
        src:img22,
        category:"Roseline and David",
      },
      {
        id:"23",
        src:img23,
        category:"Roseline and David",
      },
      {
        id:"24",
        src:img24,
        category:"Roseline and David",
      },
      {
        id:"25",
        src:img25,
        category:"Roseline and David",
      },
      {
        id:"26",
        src:img26,
        category:"Roseline and David",
      },
      {
        id:"27",
        src:img27,
        category:"Roseline and David",
      },
      {
        id:"28",
        src:img28,
        category:"Roseline and David",
      },
      {
        id:"29",
        src:img29,
        category:"Roseline and David",
      },
      {
        id:"30",
        src:img30,
        category:"Roseline and David",
      },
      {
        id:"31",
        src:img31,
        category:"Roseline and David",
      },
      {
        id:"32",
        src:img32,
        category:"Roseline and David",
      },
      {
        id:"33",
        src:img33,
        category:"Roseline and David",
      },
      {
        id:"34",
        src:img34,
        category:"Roseline and David",
      },
      {
        id:"35",
        src:img35,
        category:"Roseline and David",
      },
      {
        id:"36",
        src:img36,
        category:"Roseline and David",
      },
      {
        id:"37",
        src:img37,
        category:"Roseline and David",
      },
      {
        id:"38",
        src:img38,
        category:"Roseline and David",
      },
      {
        id:"39",
        src:img39,
        category:"Roseline and David",
      },
      {
        id:"40",
        src:img40,
        category:"Roseline and David",
      },
      {
        id:"41",
        src:img41,
        category:"Roseline and David",
      },
      {
        id:"42",
        src:img42,
        category:"Roseline and David",
      },
      {
        id:"43",
        src:img43,
        category:"Roseline and David",
      },
      {
        id:"44",
        src:img44,
        category:"Roseline and David",
      },
      {
        id:"45",
        src:img45,
        category:"Roseline and David",
      },
      {
        id:"46",
        src:img46,
        category:"Roseline and David",
      },
      {
        id:"47",
        src:img47,
        category:"Roseline and David",
      },
      {
        id:"48",
        src:img48,
        category:"Roseline and David",
      },
      {
        id:"49",
        src:img49,
        category:"Roseline and David",
      },
      {
        id:"50",
        src:img50,
        category:"Roseline and David",
      },
      {
        id:"51",
        src:img51,
        category:"Roseline and David",
      },
      {
        id:"52",
        src:img52,
        category:"Roseline and David",
      },
      {
        id:"53",
        src:img53,
        category:"Roseline and David",
      },
      {
        id:"54",
        src:img54,
        category:"Roseline and David",
      },
      {
        id:"55",
        src:img55,
        category:"Roseline and David",
      },
      {
        id:"56",
        src:img56,
        category:"Roseline and David",
      },
      {
        id:"57",
        src:img57,
        category:"Roseline and David",
      },
      {
        id:"58",
        src:img58,
        category:"Roseline and David",
      },
      {
        id:"59",
        src:img59,
        category:"Roseline and David",
      },
      {
        id:"60",
        src:img60,
        category:"Roseline and David",
      },
      {
        id:"61",
        src:img61,
        category:"Roseline and David",
      },
      {
        id:"62",
        src:img62,
        category:"Roseline and David",
      },
      {
        id:"63",
        src:img63,
        category:"Roseline and David",
      },
      {
        id:"64",
        src:img64,
        category:"Roseline and David",
      },
      {
        id:"65",
        src:img65,
        category:"Roseline and David",
      },
      {
        id:"66",
        src:img66,
        category:"Roseline and David",
      },
      {
        id:"67",
        src:img67,
        category:"Roseline and David",
      },
      {
        id:"68",
        src:img68,
        category:"Roseline and David",
      },
      {
        id:"69",
        src:img69,
        category:"Roseline and David",
      },
      {
        id:"70",
        src:img70,
        category:"Roseline and David",
      },
      {
        id:"71",
        src:img71,
        category:"Roseline and David",
      },
      {
        id:"72",
        src:img72,
        category:"Roseline and David",
      },
      {
        id:"73",
        src:img73,
        category:"Roseline and David",
      },
      {
        id:"74",
        src:img74,
        category:"Roseline and David",
      },
      {
        id:"75",
        src:img75,
        category:"Roseline and David",
      },
      {
        id:"76",
        src:img76,
        category:"Roseline and David",
      },
      {
        id:"77",
        src:img77,
        category:"Roseline and David",
      },
      {
        id:"78",
        src:img78,
        category:"Roseline and David",
      },
      {
        id:"79",
        src:img79,
        category:"Roseline and David",
      },
      {
        id:"80",
        src:img80,
        category:"Roseline and David",
      },
      
      
]


export default photo;