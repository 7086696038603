import Footer from "../../Components/layout/Logo/footer";
import "bootstrap/dist/css/bootstrap.css";
import CardImage1 from "../Wedding/Events/David-Clay _ Esther/AA-82.jpg";
import CardImage2 from "../Wedding/Events/Yao _ Pearl/AA-31.jpg";
import CardImage3 from "../Wedding/Events/Kevin _ Edith/AA-94.jpg";
import joelXsimPic from "../Wedding/Events/Joel_and_Simiane/AA-122.jpg"
import roseXdave   from "../Wedding/Events/Roseline-and-David/AA-2754.JPG"
import ewrafaXkwasi from "../Wedding/Events/Ewurafua-kwasi/AA--3.jpg"
import ConcertList from "../../Components/ConcertList";
import styles from "../../Components/layout/CategoryGrid.module.css"
import React, { useEffect } from "react";
import NaviBarlight from "../../Components/layout/NavBar_light";
const Concert_Data1= [
  {   
      id: "c1",
      link: "/david_clay_and_ester",
      image:CardImage1,
      category:"David Clay And Esther",
      description:"David Clay And Esther",
  }]
  
  const Concert_Data_2= [
      {   
          id: "c2",
          link: "/yao_and_pearl",
          image:CardImage2,
          category:"Yao And Pearl",
          description:"Yao And Pearl",
      }
  ]
  const Concert_Data_3= [
      {   
          id: "c3",
          link: "/kevin_and_edith",
          image:CardImage3,
          category:"Kevin And Edith",
          description:"Kevin And Edith",
      }

      
  ]
  const Concert_Data_4= [
    {   
        id: "c4",
        link: "/roseline_and_david",
        image:roseXdave,
        category:"Roseline And David",
        description:"Roseline And David",
    }

    
]
const Concert_Data_5= [
  {   
      id: "c5",
      link: "/joel_and_simiane",
      image:joelXsimPic,
      category:"Joel And Simiane",
      description:"Joel And Simiane",
  }

  
]
const Concert_Data_6= [
  {   
      id: "c6",
      link: "/ewurafua_and_kwasi",
      image:ewrafaXkwasi,
      category:"Ewurafua And Kwasi",
      description:"Ewurafua And Kwasi",
  }

  
]

function Wedding ()
{
  useEffect(()=>{
    document.title="Weddings"
  })
  return(
  <div>
    <NaviBarlight />
    <br/>
    <br/>
    <br/>
      <div>   
    <div className={styles.gridContainer}>
    <div className={styles.item1} >
    <ConcertList cardData={Concert_Data1} className={styles.portrait}/>
    </div>
       <div className={styles.item2}>
       <ConcertList cardData={Concert_Data_2} className={styles.portrait}/>
       </div>
       <div className={styles.item3}>
       <ConcertList cardData={Concert_Data_3} className={styles.landscape} />
       </div>
       <div className={styles.item4}>
       <ConcertList cardData={Concert_Data_4} className={styles.portrait}/>
       </div>
       <div className={styles.item5}>
       <ConcertList cardData={Concert_Data_5} className={styles.portrait}/>
       </div>
       <div className={styles.item6}>
       <ConcertList cardData={Concert_Data_6} className={styles.landscape} />
       </div>
    </div>
    </div>
    <br/>
    <Footer/>
  </div>
  );
}

export default Wedding