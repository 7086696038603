import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../../Components/layout/Logo/footer";
import NaviBarlight from "../../Components/layout/NavBar_light";
import Styles from "../About/About.module.css";
import profile from "../About/images/AbdulArafat.png";
import quote from "../About/images/Quote.jpg";
import Client from "../About/images/ClientsAndPartners.png";
import ClientM from "../About/images/clientM.jpg";
import emailjs from '@emailjs/browser';

function About() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('soccerislife01', 'template_yb9d2z1', form.current, 'okctFnBboT--eJGwf')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert('Sent')
  };
    
  useEffect(() => {
    document.title = "Book";
  });
  return (
    <div>
      <NaviBarlight />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={Styles.mainBlock}>
        <div>
          <div className={Styles.content}>
            <div className={Styles.a}>
              <div className={Styles.header}>
                <p>“A Real Piece Of Art Is A Window Into The Transcendent.”</p>
              </div>
            </div>
            <div className={Styles.Para1_2}>
              <p>
                {" "}
                This philosophy has fueled our journey for nearly a decade. For
                Arafat and the team, photography goes beyond just capturing
                images.
              </p>{" "}
              It’s an opportunity to eternalize moments that may never come
              around again. As a group, we've always been passionate about
              collecting photos, especially those candid, unguarded moments.
            </div>
            <div className={Styles.Para3_5}>
              <p>
                Recognizing the privilege to be able to use the camera as a
                time-machine, we have honed our skills to capture pure emotions
                that evoke deep nostalgia each time our work is revisited.
              </p>

              <p>
                Over the years, we've been privileged to collaborate with
                esteemed artists and institutions, including the Government of
                Ghana, Vodafone Ghana Music Awards, Cardi B in Ghana,
                Afronation, Detty Rave, All African Music Awards, Live Wyred,
                3Music Awards, Chalewote Festival, Polo Beach Club, HIBS Africa,
                Bank of Ghana, Tullow Oil, CocaCola, Sandbox Beach Club,
                Achimota Golf Club, Guide Radio, Sarkodie, and numerous others.
              </p>
              </div>
              <div className={Styles.Para5_6}>
              <p>
              Our combined efforts have also been recognized with accolades 
              such as the 2019 Accra Photoweek’s Events Photographer of the Year,
               People’s Choice Photographer of the Year, 
               and the 2022 event photographer of the year by Artpreciate Creative Awards.
              </p>
              <p>
                Together, we are excited about the uncharted paths ahead,
                constantly seeking to expand and refine our artistry.
              </p>
            </div>

            <div className={Styles.Profile}>
              <img src={profile} className={Styles.profilePic} alt="Profile" />
            </div>
            <div className={Styles.QuoteContainer}>
              <img src={quote} alt="Quote" className={Styles.Quote} />
            </div>

            <div className={Styles.Clients}>
              <img
                src={Client}
                alt="Clients And Partners Desktop"
                className={Styles.clients_and_partnersD}
              />
              <img
                src={ClientM}
                alt="Clients And Partners Desktop"
                className={Styles.clients_and_partnersM}
              />
            </div>
            <div className={Styles.formContainer}>
              <div className={Styles.main}>
                <div className={Styles.formbox}>
                  <form ref={form} onSubmit={sendEmail} className={Styles.form}>
                    <input
                      type="text"
                      name="user_name"
                      id=""
                      placeholder="Full Name"
                      className={Styles.lastName}
                    />
                    <input
                      type="email"
                      name="user_email"
                      id=""
                      placeholder="example@gmail.com"
                      className={Styles.mail}
                    />
                    <input
                      type="text"
                      name="subject"
                      id=""
                      placeholder="Subject"
                      className={Styles.subj}
                    />
                    <textarea
                      name="message"
                      id=""
                      cols="38"
                      rows="8"
                      placeholder="What do you want to know?"
                      className={Styles.textarea}
                    />
                    <button type="submit" className={Styles.submit}>
                      SEND
                    </button>
                  </form>
                </div>
                <div className={Styles.info}>
                  <div>
                    <h2>Send Us A Message</h2>
                  </div>
                  <div>You Will Hear From Us within 24 hours</div>
                  <div className={Styles.tags}>
                    <a
                      href="tel:+233 27-270-2909"
                      style={{
                        color: "black",
                        textDecoration: "none",
                        fontFamily: "dm-serif-display",
                      }}
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "7px" }}
                      >
                        <path
                          d="M19.5625 19.4219L20.4609 15.4766C20.5391 15.1901 20.5 14.9167 20.3438 14.6562C20.2135 14.3958 20.0182 14.2005 19.7578 14.0703L15.5391 12.2734C15.2786 12.1693 15.0182 12.1432 14.7578 12.1953C14.4974 12.2734 14.276 12.4167 14.0938 12.625L12.375 14.7344C11.0208 14.0573 9.8099 13.1849 8.74219 12.1172C7.67448 11.0495 6.80208 9.83854 6.125 8.48437L8.23438 6.76562C8.44271 6.58333 8.57292 6.36198 8.625 6.10156C8.70312 5.84115 8.6901 5.58073 8.58594 5.32031L6.78906 1.10156C6.65885 0.841146 6.46354 0.632812 6.20312 0.476562C5.94271 0.346354 5.66927 0.320312 5.38281 0.398437L1.4375 1.29687C1.17708 1.375 0.955729 1.51823 0.773438 1.72656C0.591146 1.96094 0.5 2.22135 0.5 2.50781C0.5 5.73698 1.30729 8.73177 2.92188 11.4922C4.51042 14.2005 6.64583 16.3359 9.32812 17.8984C12.1146 19.5391 15.1354 20.3594 18.3906 20.3594C18.651 20.3594 18.8854 20.2682 19.0938 20.0859C19.3281 19.9036 19.4844 19.6823 19.5625 19.4219ZM1.75 2.50781L5.65625 1.60937L7.45312 5.82812L4.60156 8.13281C5.53906 10.1641 6.61979 11.7917 7.84375 13.0156C9.06771 14.2396 10.6953 15.3203 12.7266 16.2578L15.0312 13.4062L19.25 15.2031L18.3516 19.1094C15.3568 19.1094 12.5703 18.3542 9.99219 16.8438C7.46615 15.3594 5.47396 13.3672 4.01562 10.8672C2.50521 8.28906 1.75 5.5026 1.75 2.50781Z"
                          fill="#0F001A"
                        />
                      </svg>
                      +233 27-270-2909{" "}
                    </a>
                    <a
                      href="tel:+233 55-250-7040"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "7px" }}
                      >
                        <path
                          d="M19.5625 19.4219L20.4609 15.4766C20.5391 15.1901 20.5 14.9167 20.3438 14.6562C20.2135 14.3958 20.0182 14.2005 19.7578 14.0703L15.5391 12.2734C15.2786 12.1693 15.0182 12.1432 14.7578 12.1953C14.4974 12.2734 14.276 12.4167 14.0938 12.625L12.375 14.7344C11.0208 14.0573 9.8099 13.1849 8.74219 12.1172C7.67448 11.0495 6.80208 9.83854 6.125 8.48437L8.23438 6.76562C8.44271 6.58333 8.57292 6.36198 8.625 6.10156C8.70312 5.84115 8.6901 5.58073 8.58594 5.32031L6.78906 1.10156C6.65885 0.841146 6.46354 0.632812 6.20312 0.476562C5.94271 0.346354 5.66927 0.320312 5.38281 0.398437L1.4375 1.29687C1.17708 1.375 0.955729 1.51823 0.773438 1.72656C0.591146 1.96094 0.5 2.22135 0.5 2.50781C0.5 5.73698 1.30729 8.73177 2.92188 11.4922C4.51042 14.2005 6.64583 16.3359 9.32812 17.8984C12.1146 19.5391 15.1354 20.3594 18.3906 20.3594C18.651 20.3594 18.8854 20.2682 19.0938 20.0859C19.3281 19.9036 19.4844 19.6823 19.5625 19.4219ZM1.75 2.50781L5.65625 1.60937L7.45312 5.82812L4.60156 8.13281C5.53906 10.1641 6.61979 11.7917 7.84375 13.0156C9.06771 14.2396 10.6953 15.3203 12.7266 16.2578L15.0312 13.4062L19.25 15.2031L18.3516 19.1094C15.3568 19.1094 12.5703 18.3542 9.99219 16.8438C7.46615 15.3594 5.47396 13.3672 4.01562 10.8672C2.50521 8.28906 1.75 5.5026 1.75 2.50781Z"
                          fill="#0F001A"
                        />
                      </svg>
                      +233 55-250-7040
                    </a>
                    <a
                      href="mailto: abdularafatphotos@gmail.com"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <svg
                        width="20"
                        height="12"
                        viewBox="0 0 20 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "7px" }}
                      >
                        <path
                          d="M18.125 0.84375L1.875 0.84375C1.35417 0.84375 0.911458 1.02604 0.546875 1.39062C0.182292 1.75521 0 2.19792 0 2.71875L0 13.9688C0 14.4896 0.182292 14.9323 0.546875 15.2969C0.911458 15.6615 1.35417 15.8438 1.875 15.8438H18.125C18.6458 15.8438 19.0885 15.6615 19.4531 15.2969C19.8177 14.9323 20 14.4896 20 13.9688V2.71875C20 2.19792 19.8177 1.75521 19.4531 1.39062C19.0885 1.02604 18.6458 0.84375 18.125 0.84375ZM1.875 2.09375L18.125 2.09375C18.3073 2.09375 18.4505 2.15885 18.5547 2.28906C18.6849 2.39323 18.75 2.53646 18.75 2.71875V4.32031C17.8385 5.10156 15.8724 6.6901 12.8516 9.08594L12.4609 9.39844C11.9401 9.84115 11.5365 10.1536 11.25 10.3359C10.7552 10.6745 10.3385 10.8438 10 10.8438C9.66146 10.8438 9.24479 10.6745 8.75 10.3359C8.4375 10.1536 8.02083 9.84115 7.5 9.39844L7.14844 9.08594C4.1276 6.6901 2.16146 5.10156 1.25 4.32031V2.71875C1.25 2.53646 1.30208 2.39323 1.40625 2.28906C1.53646 2.15885 1.69271 2.09375 1.875 2.09375ZM18.125 14.5938H1.875C1.69271 14.5938 1.53646 14.5417 1.40625 14.4375C1.30208 14.3073 1.25 14.151 1.25 13.9688L1.25 5.96094C2.16146 6.6901 3.86719 8.05729 6.36719 10.0625L6.75781 10.375C7.35677 10.8958 7.85156 11.2734 8.24219 11.5078C8.89323 11.8984 9.47917 12.0938 10 12.0938C10.5208 12.0938 11.1068 11.8984 11.7578 11.5078C12.1224 11.2734 12.6172 10.8958 13.2422 10.375L13.6328 10.0625C16.1328 8.05729 17.8385 6.6901 18.75 5.96094V13.9688C18.75 14.151 18.6849 14.3073 18.5547 14.4375C18.4505 14.5417 18.3073 14.5938 18.125 14.5938Z"
                          fill="#0F001A"
                        />
                      </svg>
                      abdularafatphotos@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
