import React from 'react'
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

function ImgCard(props){
  // function isOpen (index){
  //   console.log(index)
  // }
  return (
    <Card key={props.id} style={{overflow:"hidden",borderRadius:"0px"}} > 
        <CardActionArea  
        onClick={() => { props.isOpen(props.id)}}
        >
          <CardContent sx={{  m: "0", p: "0"}}>
            <CardMedia
              component="img"
              image={props.image}
              loading="lazy"
              alt={props.category}
              sx={props.height }
            />
          </CardContent>
        </CardActionArea>
      </Card>
  );
}

export default ImgCard;